import React, { useState } from "react";
import logo from "../../assets/logo/logo-full-default.svg";
import accountIcon from "../../assets/icons/account-icon.svg";
import InfoModal from "../Global/InfoModal";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

const BasicHeader = ({ profile }) => {
  const [modalShown, setModalShown] = useState(false);
  return (
    <div className="dashboard-header basic">
      <img src={logo} className="logo" alt="" />
      <div className="actionsMenu">
        <span className="orgName">{profile.organizationName}</span>
        <img
          src={accountIcon}
          className="account-icon"
          alt="Account"
          onClick={() => setModalShown(true)}
        />
      </div>
      {modalShown && (
        <InfoModal title="My account" toggleModal={() => setModalShown(false)}>
          This page is still under construction. Please reach out to our support
          at support@catchergames.com for information about your account.
        </InfoModal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  return {
    profile,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(BasicHeader);
