import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { guides } from "../../../helpers/guides";

// Generate guide list from object. Returns array

const generateGuidePreviewList = (gameInfo) => {
  console.log(gameInfo);
  let { gameEnvironment, subGameEnvironment, inviteType } = gameInfo;

  if (!gameEnvironment || !inviteType) return;
  subGameEnvironment =
    subGameEnvironment === null ? "general" : subGameEnvironment;

  if (
    !guides.hasOwnProperty(gameEnvironment) ||
    !guides[gameEnvironment].hasOwnProperty(subGameEnvironment)
  ) {
    return guides.default[inviteType];
  }
  return guides[gameEnvironment][subGameEnvironment][inviteType];
};

// Component

function GuideList(props) {
  return (
    <ul className="guide-list">
      {generateGuidePreviewList({
        gameEnvironment: props.gameEnvironment,
        subGameEnvironment: props.subGameEnvironment,
        inviteType: props.inviteType,
      }).map((guideItem, i) => (
        <li key={i}>{guideItem}</li>
      ))}
    </ul>
  );
}

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const gameEnvironment = profile.gameEnvironment;
  const subGameEnvironment = profile.subGameEnvironment;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const gameStarted = game.ongoing;
  return {
    auth,
    profile,
    gameEnvironment,
    subGameEnvironment,
    gameStarted,
    inviteType: state.playerInfo.inviteType,
  };
  // auth: state.firebase.auth,
  // profile: state.firebase.profile,
  // gameEnvironment: state.
  // inviteType: state.playerInfo.inviteType,
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(GuideList);
// export default connect(mapStateToProps)(GuideList);
