import React from "react";
import OverlayPage from "../Global/OverlayPage";
import PlayerList from "./PlayerList/PlayerList";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import moment from "moment";

const PlayerListOverlay = (props) => {
  const playersArr = [
    {
      id: 123,
      firstName: "Sebastian",
      lastName: "Thunman",
      email: "sebastian@thunman.se",
    },
    {
      id: 432,
      firstName: "Erik",
      lastName: "Enger Karlson",
      email: "erik.engerkarlson@gmail.com",
    },
  ];

  const goBack = () => {
    props.history.push("/player-setup");
  };
  return (
    <OverlayPage toggleOverlay={goBack}>
      <div className="list-overlay">
        <h2>Joined players</h2>
        <div className="player-list-amount">
          Total players <span>{props.playersNum}</span>
        </div>
        {isLoaded(props.playersObj) ? (
          props.playersObj ? (
            <PlayerList players={props.playersArr} />
          ) : (
            <p>No joined players</p>
          )
        ) : (
          <p>Loading players</p>
        )}
      </div>
    </OverlayPage>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const playersNum = game.playersNum || 0;
  const playersObj = state.firestore.data.playerInstances || null;
  const playersArr = playersObj
    ? Object.keys(playersObj).map((instanceId) => ({
        firstName: playersObj[instanceId].firstName,
        lastName: playersObj[instanceId].lastName,
        formatedJoinedDate: moment(
          playersObj[instanceId].joinedDate
        ).calendar(),
      }))
    : null;
  return {
    auth,
    profile,
    playersObj: state.firestore.data.playerInstances,
    playersArr,
    playersNum,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "games",
      doc: props.profile.currentGameId || "filler",
      subcollections: [{ collection: "playerInstances" }],
      orderBy: ["joinedDate", "desc"],
      storeAs: "playerInstances",
    },
  ])
)(PlayerListOverlay);
