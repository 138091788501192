import React, { useState } from "react";
import InfoModal from "../Global/InfoModal";

const LearnMoreLink = ({ linkText, title = linkText, modalText }) => {
  const [modalShown, setModalShown] = useState(false);
  return (
    <div>
      <p onClick={() => setModalShown(true)} className="learn-more-link">
        {linkText}
      </p>
      {modalShown && (
        <InfoModal title={title} toggleModal={() => setModalShown(false)}>
          {modalText}
        </InfoModal>
      )}
    </div>
  );
};

export default LearnMoreLink;
