import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { loadStripe } from "@stripe/stripe-js";
import {
  freeGamePlayerLimit,
  getData,
  playerPriceInUSD,
  postData,
} from "../../general";

import { ReactComponent as CardIcon } from "../../assets/icons/payment-card-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import MainButton from "../Elements/MainButton";

const stripePromise = loadStripe("pk_live_4wNr5d747pBLILKUxoZZ326F"); // pk_test_wG3qVJq2aV4ifCWV67nXSGa7

const PaymentSection = ({ profile, game }) => {
  const { playersNum, paid, appliedDiscount } = game;
  const [gameCostObj, setGameCostObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const { currentGameId: gameId, userId: organizerId } = profile;

  const handlePaymentClick = async () => {
    try {
      setLoading(true);
      const stripe = await stripePromise;
      const postBody = {
        gameId,
        organizerId,
      };
      // TODO: loading animation
      const response = await postData("/payGame", postBody);
      const { sessionId, paymentAmountZero } = await response.json();
      setLoading(false);
      if (paymentAmountZero) console.log("free game");
      else {
        const result = await stripe.redirectToCheckout({ sessionId });

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData("/gameCost").then(async (res) => {
      const {
        playersNum,
        centCostPerPlayer,
        preDiscountCentCost,
        totalCentCost,
        discountCode,
      } = await res.json();
      setGameCostObj({
        playersNum,
        usdCostPerPlayer: centCostPerPlayer / 100,
        usdPreDiscountCost: preDiscountCentCost / 100,
        usdTotalCost: totalCentCost / 100,
        discountCode: discountCode
          ? {
              code: discountCode.code,
              usdTotalDiscount: discountCode.centDiscount / 100,
            }
          : null,
      });
    });
  }, [playersNum, appliedDiscount]);

  console.log(gameCostObj);

  return (
    <div className="payment-section section-container">
      <h2>Checkout</h2>
      {playersNum > freeGamePlayerLimit ? (
        !paid ? (
          gameCostObj ? (
            <div>
              <table className="payment-info">
                <tbody>
                  <tr>
                    <td>Players</td>
                    <td>{gameCostObj.playersNum}</td>
                  </tr>
                  <tr>
                    <td>Cost per player</td>
                    <td>${gameCostObj.usdCostPerPlayer}</td>
                  </tr>
                  {gameCostObj.discountCode && (
                    <tr className="discount">
                      <td>Discount code</td>
                      <td>
                        -${gameCostObj.discountCode.usdTotalDiscount}
                        <span>
                          (
                          {(gameCostObj.discountCode.usdTotalDiscount /
                            gameCostObj.usdPreDiscountCost) *
                            100}
                          %)
                        </span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Total</td>
                    <td>${gameCostObj.usdTotalCost}</td>
                  </tr>
                </tbody>
              </table>
              <MainButton
                className="payment-btn main-btn--with-icon"
                onClick={handlePaymentClick}
                loading={loading}
              >
                <CardIcon />
                Checkout
              </MainButton>
              {/* <button
                className="payment-btn main-btn main-btn--with-icon"
                onClick={handlePaymentClick}
              ></button> */}
            </div>
          ) : (
            <div className="spinner" style={{ marginBottom: 20 }} />
          )
        ) : (
          <p className="success-p">
            <CheckmarkIcon className="checkmark-icon" />
            Checkout successful
          </p>
        )
      ) : (
        <p className="free-game-p">
          Games with up to {freeGamePlayerLimit} players do not require payment
        </p>
      )}
      <p className="bottom-info">
        Adding more players after checkout will cost more and can't be done
        until after the game has started
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const playersNum = game.playersNum || 0;
  const paid = game.paid;
  return {
    auth,
    profile,
    game,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(PaymentSection);
