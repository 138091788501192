import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import { startGame } from "../../redux/actions/gameInfoActions";
import { minPlayersAmount, freeGamePlayerLimit } from "../../general";

const StartGameSection = (props) => {
  const { paid, playersNum } = props;
  const playerRequirementCheck = playersNum >= minPlayersAmount;
  const startNotAllowed =
    playersNum < minPlayersAmount ||
    (playersNum > freeGamePlayerLimit && !paid);
  const startGamePrompt = () => {
    console.log("Starting game");
    props.startGame();
  };

  return (
    <div className="start-game-section section-container">
      <h2>Start game</h2>
      {startNotAllowed ? (
        playerRequirementCheck ? (
          <p className="pre-payment-info">
            You may start the game at any time after checkout
          </p>
        ) : (
          <p className="pre-payment-info">
            A minimum of {minPlayersAmount} joined players is required to start
            the game
          </p>
        )
      ) : null}
      <button
        className="main-btn main-btn--with-icon"
        onClick={startGamePrompt}
        disabled={startNotAllowed}
      >
        <CheckmarkIcon />
        Start game
      </button>
      {/* <p>All advanced settings in the setup tab will be permanent after the game has started</p> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const playersNum = game.playersNum || 0;
  const paid = game.paid;
  return {
    auth,
    profile,
    playersNum,
    paid,
  };
};

export default compose(
  connect(mapStateToProps, { startGame }),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(StartGameSection);

// export default StartGameSection;
