import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";

import InviteTypeSelection from "./InviteTypeSelection";

import InviteSection from "../InviteSection";
import GuideSection from "./GuideSection";
import PlayerListSection from "./PlayerListSection";

import InviteListOverlay from "../InviteListOverlay";
import PlayerListOverlay from "../PlayerListOverlay";

function PlayerSetup(props) {
  if (!props.inviteType) return <InviteTypeSelection />;
  else
    return (
      <div>
        <div className="player-setup column-wrapper">
          <div className="column">
            <InviteSection />
            <PlayerListSection />
          </div>
          <div className="column">
            <GuideSection />
          </div>
        </div>
        <Switch>
          <Route path="/player-setup/invites" component={InviteListOverlay} />
          <Route path="/player-setup/players" component={PlayerListOverlay} />
        </Switch>
      </div>
    );
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  inviteType: state.playerInfo.inviteType,
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [{ collection: "games", doc: props.profile.currentGameId }])
)(PlayerSetup);
