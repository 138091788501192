import { SHOW_LOADER, HIDE_LOADER } from "./types";
import { postData } from "../../general";
import { defaultRules } from "../../helpers/defaultRules";
import { getFirebase } from "react-redux-firebase";

export const submitOnboardingData =
  (data) => (dispatch, getState, getFirebase) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: SHOW_LOADER });

      const firebase = getFirebase();
      const firestore = firebase.firestore();

      const {
        gameEnvironment,
        gameEnvironmentName,
        location,
        potPlayers,
        subGameEnvironment,
      } = data;

      const rulesString = JSON.stringify(
        defaultRules[gameEnvironment][subGameEnvironment || "general"] ||
          defaultRules.default.general
      );

      const postBody = {
        uid: getState().firebase.auth.uid,
        gameLocation: location,
        potPlayersNum: potPlayers,
        rulesString,
      };
      console.log(postBody);
      const createGamePromise = postData("/createGame", postBody);
      const submitDataToFBPromise = firestore
        .collection("organizers")
        .doc(getState().firebase.auth.uid)
        .update({ gameEnvironment, subGameEnvironment, gameEnvironmentName });

      Promise.all([createGamePromise, submitDataToFBPromise])
        .then((values) => {
          dispatch({ type: HIDE_LOADER });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: HIDE_LOADER });
          reject(err);
        });
    });
  };

export const createNewGame =
  (setupData) => (dispatch, getState, getFirebase) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: SHOW_LOADER });

        const { location, potPlayers } = setupData;

        const { gameEnvironment, subGameEnvironment } =
          getState().firebase.profile;

        const rulesString = JSON.stringify(
          defaultRules[gameEnvironment][subGameEnvironment || "general"] ||
            defaultRules.default.general
        );

        const postBody = {
          uid: getState().firebase.auth.uid,
          gameLocation: location,
          potPlayersNum: potPlayers,
          rulesString,
        };
        await postData("/createGame", postBody);

        dispatch({ type: HIDE_LOADER });
        resolve(true);
      } catch (err) {
        dispatch({ type: HIDE_LOADER });
        reject(err);
      }
    });
  };

export const submitRules =
  (rulesString) => (dispatch, getState, getFirebase) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: SHOW_LOADER });
      rulesString =
        typeof rulesString !== "string"
          ? JSON.stringify(rulesString)
          : rulesString;
      const currentTime = new Date().getTime();

      const firebase = getFirebase();
      const firestore = firebase.firestore();
      firestore
        .collection("games")
        .doc(getState().firebase.profile.currentGameId)
        .collection("otherGameInfo")
        .doc("rules")
        .update({ content: rulesString, latestUpdated: currentTime })
        .then(() => {
          dispatch({ type: HIDE_LOADER });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: HIDE_LOADER });
          reject(err);
        });
    });
  };

export const updateGameOptions =
  (optionsObj = {}) =>
  (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SHOW_LOADER });
      try {
        const postBody = {
          targetsNum: optionsObj.targetsNum || 1,
          livesNum: optionsObj.livesNum || 1,
        };
        await postData("/updateTargetsNum", postBody);
        dispatch({ type: HIDE_LOADER });
        resolve(true);
      } catch (err) {
        dispatch({ type: HIDE_LOADER });
        reject(err);
      }
    });
  };

export const sendGameMessage =
  (messageText) => (dispatch, getState, getFirebase) => {
    return new Promise(async (resolve, reject) => {
      if (!messageText) {
        reject({ message: "Please write a message" });
        return;
      }
      dispatch({ type: SHOW_LOADER });
      try {
        const postBody = { messageText };
        await postData("/publishGameMessage", postBody);
        dispatch({ type: HIDE_LOADER });
        resolve(true);
      } catch (err) {
        dispatch({ type: HIDE_LOADER });
        reject(err);
      }
    });
  };

export const startGame = () => async (dispatch, getState, getFirebase) => {
  try {
    dispatch({ type: SHOW_LOADER });
    const postBody = {
      uid: getState().firebase.auth.uid,
      gameId: getState().firebase.profile.currentGameId,
    };
    await postData("/startGame", postBody);
    dispatch({ type: HIDE_LOADER });
    return true;
  } catch (err) {
    dispatch({ type: HIDE_LOADER });
    throw err;
  }
};

export const endGame = () => (dispatch, getState, getFirebase) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: SHOW_LOADER });
      await postData("/endGameManually");
      dispatch({ type: HIDE_LOADER });
      resolve(true);
    } catch (err) {
      dispatch({ type: HIDE_LOADER });
      reject(err);
    }
  });
};
