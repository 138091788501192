import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import LoginForm from "./LoginForm";

const LoginPage = (props) => {
  return (
    <div className="auth-page">
      {props.auth.uid && <Redirect to="/" />}
      <div className="form-container">
        <h1>Login as host</h1>
        <LoginForm />
        <p className="alt-auth-prompt">
          Don't have an account? <Link to="/register">Register here</Link>
        </p>
      </div>
      <p className="mobile-warning">This page is only available on desktops</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

export default connect(mapStateToProps)(LoginPage);
