import { NEW_ORGANIZER, SHOW_LOADER, HIDE_LOADER } from "./types";
import { postData } from "../../general";

export const registerOrganizer = (formData) => (
  dispatch,
  getState,
  getFirebase
) => {
  return new Promise((resolve, reject) => {
    const { organizationEmail, organizationName, password } = formData;

    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(organizationEmail, password)
      .then(async (credentials) => {
        dispatch({ type: SHOW_LOADER });

        const { email, uid } = credentials.user;

        const postBody = {
          uid: uid,
          email: email,
          organizationName: organizationName,
        };

        console.log(postBody);
        console.log(credentials.user);

        const idToken = await firebase.auth().currentUser.getIdToken();

        postData("/registerOrganizer", postBody, idToken)
          .then(() => {
            dispatch({ type: HIDE_LOADER });
            resolve();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
