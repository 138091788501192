import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";

const RulesDisplay = (props) => {
  const paramsString = props.location.search;
  const params = new URLSearchParams(paramsString);
  const tags = params.get("recentlyUpdated");
  const recentlyUpdated = tags === "true" ? true : false;
  if (recentlyUpdated)
    setTimeout(() => {
      props.history.replace(props.location.pathname);
    }, 1500);

  return (
    <div className="rules-display">
      {isLoaded(props.rules) && !isEmpty(props.rules) ? (
        JSON.parse(props.rules).map((section, i) => (
          <div
            key={i}
            className={`section ${recentlyUpdated ? "updated" : null}`}
          >
            <h2>{section.title}</h2>
            <ul>
              {section.content.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p>Loading rules...</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const rulesObj = state.firestore.data.rules || {};
  const rules = rulesObj.content;
  return {
    profile,
    rules,
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps),
    firestoreConnect((props) => [
      {
        collection: "games",
        doc: props.profile.currentGameId,
        subcollections: [{ collection: "otherGameInfo", doc: "rules" }],
        storeAs: "rules",
      },
    ])
  )(RulesDisplay)
);

// export default RulesDisplay;
