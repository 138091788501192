import { SUBMIT_ONBOARDING_DATA } from '../actions/types';

const initialState = {
	gameStarted: false,
	onboardingComplete: true,
	gameEnvironment: "school",
	type: "elementarySchool",
	completedSetupTasks: {
		rules: false
	}
}

export default function(state = initialState, action) {
	switch(action.type) {
		case SUBMIT_ONBOARDING_DATA:
			return {
				...state,
				...action.payload
			}
		default: 
			return state;
	}
}