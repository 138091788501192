import React from "react";

import PlayerListItem from "./PlayerListItem";
import PlayerListHeader from "./PlayerListHeader";

const PlayerList = ({
  players,
  gameStarted = false,
  functions = {},
  className,
}) => {
  return (
    <div className="player-list">
      <table className={className ? className : null}>
        <thead>
          <PlayerListHeader
            player={(players || [])[0]}
            gameStarted={gameStarted}
          />
        </thead>
        <tbody>
          {players.map((player, idx) => (
            <PlayerListItem
              key={player.playerInstanceId}
              player={player}
              gameStarted={gameStarted}
              functions={functions}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerList;
