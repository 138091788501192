import React from "react";
import schoolVector from "../../assets/vectors/school-option-vector.svg";
import campVector from "../../assets/vectors/camp-option-vector.svg";
import eventVector from "../../assets/vectors/event-option-vector.svg";
import officeVector from "../../assets/vectors/office-option-vector.svg";

const SecondStep = ({ changeStep }) => {
  return (
    <div className="second-step">
      <h1 className="primary-heading">Where will your game be played?</h1>
      <div className="card-container">
        <div
          className="card"
          onClick={() => {
            changeStep("next", { gameEnvironment: "school" });
          }}
        >
          <img className="card-img" src={schoolVector} alt="Failed to load" />
          <h4 className="card-label">School</h4>
        </div>
        <div
          className="card"
          onClick={() => {
            changeStep("next", { gameEnvironment: "camp" });
          }}
        >
          <img className="card-img" src={campVector} alt="Failed to load" />
          <h4 className="card-label">Camp</h4>
        </div>
        <div
          className="card"
          onClick={() => {
            changeStep("next", { gameEnvironment: "event" });
          }}
        >
          <img className="card-img" src={eventVector} alt="Failed to load" />
          <h4 className="card-label">Event/conference</h4>
        </div>
        <div
          className="card"
          onClick={() => {
            changeStep("next", { gameEnvironment: "office" });
          }}
        >
          <img className="card-img" src={officeVector} alt="Failed to load" />
          <h4 className="card-label">Office</h4>
        </div>
      </div>
    </div>
  );
};

export default SecondStep;
