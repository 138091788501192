import React, { useState } from "react";

import { ReactComponent as MailIcon } from "../../assets/icons/mail-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import closeIcon from "../../assets/icons/close-icon.svg";
import { postData, validateEmail } from "../../general";

const SupportModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formValid, setFormValid] = useState(false);

  const modalClass = modalOpen ? "support-modal modal-open" : "support-modal";
  const btnClass = modalOpen ? "support-btn modal-open" : "support-btn";

  const validateForm = (newEmail) => {
    setFormValid(validateEmail(newEmail));
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setTimeout(() => {
      setErrorMessage(null);
      setFormSuccess(false);
    }, 1000);
  };

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      if (formValid) {
        const postBody = {
          firstName,
          email,
          message,
        };
        setSubmittingForm(true);
        await postData("/emailSupport", postBody);
        setSubmittingForm(false);
        setFormSuccess(true);
        setFirstName("");
        setEmail("");
        setMessage("");
      }
    } catch (err) {
      setSubmittingForm(false);
      setErrorMessage(
        "Something went wrong, please reload the page and try again"
      );
    }
  };

  return (
    <div>
      <div className={btnClass} onClick={toggleModal}>
        <MailIcon />
      </div>
      <div className={modalClass}>
        {!formSuccess ? (
          <>
            <img
              src={closeIcon}
              alt="close"
              className="close-icon"
              onClick={toggleModal}
            />

            <div className="heading-container">
              <div className="icon-badge">
                <MailIcon />
              </div>
              <div>
                <h2>Contact our support</h2>
                <p>Leave your message and we'll get back to you shortly.</p>
              </div>
            </div>
            <form onSubmit={submitForm}>
              <label htmlFor="firstName">First name</label>
              <input
                id="firstName"
                placeholder="John"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />

              <label htmlFor="email">Organisation email *</label>
              <input
                id="email"
                type="email"
                placeholder="example@domain.com"
                value={email}
                onChange={(e) => {
                  validateForm(e.target.value);
                  setEmail(e.target.value);
                }}
                required
              />

              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                rows="5"
                placeholder="Your questions and comments"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                className="main-btn"
                type="submit"
                disabled={!formValid || submittingForm}
              >
                Submit
              </button>
              {submittingForm && (
                <p className="submitting-message">Submitting form...</p>
              )}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>
          </>
        ) : (
          <div className="success-container">
            <div className="success-badge">
              <CheckmarkIcon />
            </div>
            <h2>Message sent</h2>
            <p>We'll get back to you as soon as possible!</p>
            <button className="main-btn" onClick={toggleModal}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportModal;
