import React from "react";

import GameStats from "./GameStats";
import GameLog from "./GameLog";
import GameMessageForm from "./GameMessageForm";

const OverviewPage = () => {
  return (
    <div className="game-overview-page column-wrapper">
      <div className="column column--left">
        <div className="section-container">
          <h2>Status</h2>
          <GameStats />
        </div>
        <div className="section-container">
          <h2>Game messages</h2>
          <GameMessageForm />
        </div>
      </div>
      <div className="column column--right">
        <div className="section-container">
          <h2>Game log</h2>
          <GameLog />
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
