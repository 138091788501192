import React, { useState } from "react";
import { connect } from "react-redux";
import * as firebase from "firebase";
import { Link } from "react-router-dom";

const LoginForm = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputFormValid()) {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
      } catch (err) {
        setError(err);
      }
    }
  };

  const inputFormValid = () => {
    if (email && email.trim() !== "" && password && password.trim() !== "")
      return true;
    else return false;
  };
  return (
    <form onSubmit={handleSubmit}>
      {error && <div className="form-error">{error.message}</div>}
      <label>Organization email</label>
      <input
        type="email"
        placeholder="example@domain.com"
        name="organizationEmail"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        required
      />
      <br />
      <label>Password</label>
      <input
        type="password"
        placeholder="••••••••••"
        name="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        required
      />
      <Link to="/reset-password">Forgot your password?</Link>
      <br />
      <button type="submit" className="submit-btn" disabled={!inputFormValid()}>
        Login as host
      </button>
    </form>
  );
};

export default LoginForm;
