import React from "react";

const PlayerListHeader = ({
  player: {
    firstName,
    email,
    alive,
    points,
    rank,
    formatedInviteDate,
    formatedJoinedDate,
  },
  gameStarted,
}) => {
  return (
    <tr>
      {firstName && (
        <th>
          <h6>Full name</h6>
        </th>
      )}
      {email && (
        <th>
          <h6>Email</h6>
        </th>
      )}
      {formatedInviteDate && (
        <th>
          <h6>Invite sent</h6>
        </th>
      )}
      {formatedJoinedDate && (
        <th>
          <h6>Joined game</h6>
        </th>
      )}
      {points >= 0 && (
        <th>
          <h6>Score</h6>
        </th>
      )}
      {points >= 0 && (
        <th>
          <h6>Rank</h6>
        </th>
      )}
      {(alive == true || alive == false) && (
        <th>
          <h6>Status</h6>
        </th>
      )}
      {gameStarted && (
        <th>
          <h6>More</h6>
        </th>
      )}
    </tr>
  );
};

export default PlayerListHeader;
