import React, { Component } from "react";
import { withRouter, NavLink, useLocation } from "react-router-dom";

const PreGameNavbar = () => {
  const activeStyle = {
    // borderBottom: "2px solid #FF5C32",
    color: "black",
  };

  return (
    <div className="game-started-nav-container">
      <div className="game-started-nav">
        <NavLink className="link" to="/home" activeStyle={activeStyle}>
          <div className="link-text">Home</div>
        </NavLink>
        <NavLink className="link" to="/players" activeStyle={activeStyle}>
          <div className="link-text">Players</div>
        </NavLink>
        <NavLink className="link" to="/game-events" activeStyle={activeStyle}>
          <div className="link-text">Game events</div>
        </NavLink>
        <NavLink className="link" to="/game-settings" activeStyle={activeStyle}>
          <div className="link-text">Game settings</div>
        </NavLink>
        <div className="active-underline"></div>
      </div>
    </div>
  );
};

export default withRouter(PreGameNavbar);
