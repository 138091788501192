import React from "react";
import { connect } from "react-redux";

const FullPageLoader = props => {
  return props.loading ? (
    <div className="full-page-loader">
      <div className="loading-icon">loading...</div>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  loading: state.application.loading,
});

export default connect(mapStateToProps)(FullPageLoader);
