import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import PaymentSection from "./PaymentSection";
import StartGameSection from "./StartGameSection";
import DiscountCodeSection from "./DiscountCodeSection";

const CheckoutPage = ({ paid }) => {
  if (isLoaded(paid))
    return (
      <div className="checkout-page column-wrapper">
        <div className="column">
          <PaymentSection paid={paid} />
          {!paid && <DiscountCodeSection />}
        </div>
        <div className="column">
          <StartGameSection paid={paid} />
        </div>
      </div>
    );
  else return <p>Loading...</p>;
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const paid = game.paid;
  return {
    auth,
    profile,
    paid,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(CheckoutPage);
