import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { createNewGame } from "../../redux/actions/gameInfoActions";

import { objToArr } from "../../general";
import BasicHeader from "../Layout/BasicHeader";
import GameListItem from "./GameListItem";
import DefaultModal from "../Global/DefaultModal";

import { ReactComponent as PlusIcon } from "../../assets/icons/small-plus-icon.svg";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import SearchLocationInput from "../Elements/SearchLocationInput";

const GamesPage = ({ auth, profile, gamesArr, latestGame, createNewGame }) => {
  const [showNewGameModal, setShowNewGameModal] = useState(false);
  const [validatedForm, setValidatedForm] = useState(true);
  const [location, setLocation] = useState("");
  const [potPlayers, setPotPlayers] = useState("");

  const { gameEnvironment } = profile;

  useEffect(() => {
    const { gameLocation, potPlayersNum } = latestGame;
    if (gameLocation) setLocation(gameLocation);
    if (potPlayersNum) setPotPlayers(latestGame.potPlayersNum);
  }, [latestGame]);

  useEffect(() => {
    if (location.trim() !== "" && potPlayers > 0) setValidatedForm(true);
    else setValidatedForm(false);
  }, [location, potPlayers]);

  const toggleModal = () => {
    setShowNewGameModal((prevState) => !prevState);
  };

  const createGame = async () => {
    if (validatedForm) {
      try {
        await createNewGame({ location, potPlayers });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const locationLabel = {
    school: "Location of the school",
    camp: "Location of the camp",
    event: "Location of the event",
    office: "Office location",
  };

  const potPlayersLabel = {
    school: "Number of students at the school",
    camp: "Number of camp participants",
    event: "Number of event attendants",
    office: "Number of co-workers at the office",
  };

  const redirectCheck = () => {
    if (!auth.uid) return <Redirect to="login" />;
    if (isLoaded(profile) && profile.currentGameId) return <Redirect to="/" />;
  };

  return (
    <div className="games-center-page">
      {redirectCheck()}

      <DefaultModal showModal={showNewGameModal} toggleModal={toggleModal}>
        <h1>Create new game</h1>
        <p className="modal-info">
          Please make sure the information below is correct
        </p>
        <div className="content-container">
          <h6>{locationLabel[gameEnvironment]}</h6>
          <SearchLocationInput
            placeholder="City"
            onSelection={setLocation}
            defaultValue={location}
          />
          <h6>{potPlayersLabel[gameEnvironment]}</h6>
          <input
            type="number"
            value={potPlayers}
            onChange={(e) => setPotPlayers(e.target.value)}
          />
          <button
            className="main-btn"
            onClick={createGame}
            disabled={!validatedForm}
          >
            Create game
          </button>
        </div>
      </DefaultModal>

      <BasicHeader />
      <div className="dashboard-container dashboard-container--between-games">
        <div className="controls-container">
          <button
            className="main-btn main-btn--with-icon"
            onClick={toggleModal}
          >
            <PlusIcon />
            Create new game
          </button>
        </div>
        <div className="section-container">
          <h2>Results from last game</h2>
          <p>Coming soon</p>
        </div>
        <div className="section-container">
          <h2>Previous games</h2>
          <div className="game-list">
            {gamesArr.map((game, index) => (
              <GameListItem game={game} index={index} key={game.gameId} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const gamesArr = objToArr(games, "gameId");
  gamesArr.sort((a, b) => b.startDate - a.startDate);
  const latestGame = gamesArr[0] || {};
  return {
    auth,
    profile,
    gamesArr,
    latestGame,
  };
};

export default compose(
  connect(mapStateToProps, { createNewGame }),
  firestoreConnect((props) => [
    {
      collection: "games",
      where: ["organizerId", "==", props.profile.userId || "filler"],
      // orderBy: ["endedDate", "desc"], // Requires index
    },
  ])
)(GamesPage);
