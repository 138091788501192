import { combineReducers } from 'redux';
import applicationReducer from './applicationReducer';
import playerReducer from './playerReducer';
import gameInfoReducer from './gameInfoReducer';
import errorReducer from './errorReducer';
import { firestoreReducer } from "redux-firestore"
import { firebaseReducer } from "react-redux-firebase"

export default combineReducers({
	application: applicationReducer,
	gameInfo: gameInfoReducer,
	playerInfo: playerReducer,
	error: errorReducer,
	firestore: firestoreReducer,
	firebase: firebaseReducer
});