import React, { Component } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import BasicHeader from "./BasicHeader";
import PreGameNavbar from "./PreGameNavbar";
import SetupPage from "../Setup/SetupPage";
import PlayerSetupPage from "../Players/Setup/PlayerSetupPage";
import CheckoutPage from "../Checkout/CheckoutPage";

const PreGameUI = () => {
  const location = useLocation();
  const redirectCheck = () => {
    if (!location.pathname.match(/setup|player-setup|checkout/))
      return <Redirect to="/setup" />;
  };
  return (
    <div>
      {redirectCheck()}
      <BasicHeader />
      <PreGameNavbar />
      <div className="dashboard-container">
        <Switch>
          <Route path="/setup" component={SetupPage} />
          <Route path="/player-setup" component={PlayerSetupPage} />
          <Route path="/checkout" component={CheckoutPage} />
        </Switch>
      </div>
    </div>
  );
};

export default PreGameUI;
