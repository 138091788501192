import { SET_INVITE_TYPE, FETCH_INVITES, INVITE_PLAYERS } from '../actions/types';

const initialState = {
	inviteType: "PRIVATE",
	numInvites: 0,
	invites: [],
	numPlayers: 0,
	players: []
}

export default function(state = initialState, action) {
	switch(action.type) {
		case SET_INVITE_TYPE:
			return {
				...state,
				inviteType: action.payload
			}
		case FETCH_INVITES:
			return {
				...state,
				items: action.payload
			}
		case INVITE_PLAYERS:
			return  {
				...state,
				numInvites: state.numInvites + action.payload.length,
				players: [
					...action.payload,
					...state.players
				]
			}
		default: 
			return state;
	}
}