// Tips and tricks list for attracting players for gamehosts

export const guides = {
  school: {
    elementarySchool: {
      PRIVATE: [
        "Printing out posters and hanging them around the school is a cost-effective way to advertise the upcoming game. Feel free to use our template for your posters!",
        "The teachers can be informed about the game and asked to relay that information to each respective class.",
        "At the next student council meeting, each class representative can be put in charge to inform their respective classes.",
        "The school can be asked to include information about the game in the newsletters",
      ],
      PUBLIC: [
        "Printing out posters and hanging them around the school is a cost-effective way to advertise the upcoming game. Feel free to use our template for your posters!",
        "The teachers can be informed about the game and asked to relay that information to each respective class.",
        "At the next student council meeting, each class representative can be put in charge to inform their respective classes.",
        "The school can be asked to include information about the game in the school’s newsletters and on the school’s website/social media.",
      ],
    },
    highSchool: {
      PRIVATE: [
        "An email can be sent out to each student every week a month leading up to the game to inform them about the game in different stages, and reminding them about signing up.",
        "Printing out posters and hanging them around the school is a cost-effective way to advertise the upcoming game. Feel free to use our template for your posters!",
        "The teachers can be informed about the game and asked to relay that information to each respective class.",
        "At the next student council meeting, each class representative can be put in charge to inform their respective classes.",
        "Every form of social media can be utilized to effectively spread information, either through the school’s official pages or the student body’s channels.",
      ],
      PUBLIC: [
        "An email can be sent out to each student every week a month leading up to the game to inform them about the game in different stages, and reminding them about signing up.",
        "Printing out posters and hanging them around the school is a cost-effective way to advertise the upcoming game. Feel free to use our template for your posters!",
        "The teachers can be informed about the game and asked to relay that information to each respective class.",
        "At the next student council meeting, each class representative can be put in charge to inform their respective classes.",
        "Every form of social media can be utilized to effectively spread information, either through the school’s official pages or the student body’s channels.",
      ],
    },
    university: {
      PRIVATE: [
        "An email can be sent out to each student every week a month leading up to the game to inform them about the game in different stages, and reminding them about signing up.",
        "Printing out posters and hanging them around the school is a cost-effective way to advertise the upcoming game. Feel free to use our template for your posters!",
        "At the next student council meeting, each class representative can be put in charge to inform their respective classes.",
        "Every form of social media can be utilized to effectively spread information, either through the school’s official pages or the student body’s channels.",
        "A separate social media account can be set up dedicated to the catcher games of the school, where every potential participant can get their information.",
      ],
      PUBLIC: [
        "An email can be sent out to each student every week a month leading up to the game to inform them about the game in different stages, and reminding them about signing up.",
        "Printing out posters and hanging them around the school is a cost-effective way to advertise the upcoming game. Feel free to use our template for your posters!",
        "At the next student council meeting, each class representative can be put in charge to inform their respective classes.",
        "Every form of social media can be utilized to effectively spread information, either through the school’s official pages or the student body’s channels.",
        "A separate social media account can be set up dedicated to the catcher games of the school, where every potential participant can get their information.",
      ],
    },
  },
  camp: {
    general: {
      PRIVATE: [
        "In the weeks leading up to the camp, an email can be sent out with information regarding the game.",
        "The camp supervisors should inform the participants of the game at the very first assembly.",
        "Every form of social media can be utilized to effectively spread information.",
      ],
      GENERAL: [
        "In the weeks leading up to the camp, an email can be sent out with information regarding the game.",
        "The camp supervisors should inform the participants of the game at the very first assembly.",
        "Every form of social media can be utilized to effectively spread information.",
      ],
    },
  },
  event: {
    general: {
      PRIVATE: [
        "In conjunction with the advertisement of the event/conference, the game can be teased and further explained during the start. The game can later be elaborated upon in conjunction with further information releases, where the Catcher Games game can be presented in its entirety.",
        "The organizer could reach out to all the participants of the upcoming event/conference, either through email or direct messaging, to inform them about the game.",
      ],
      GENERAL: [
        "In conjunction with the advertisement of the event/conference, the game can be teased and further explained during the start. The game can later be elaborated upon in conjunction with further information releases, where the Catcher Games game can be presented in its entirety.",
        "The organizer could reach out to all the participants of the upcoming event/conference, either through email or direct messaging, to inform them about the game.",
      ],
    },
  },
  event: {
    general: {
      PRIVATE: [
        "Information about the game can be easily relayed during an office announcement which can be made during a company meeting.",
        "An email can be sent out every week a month leading up to the game to inform them about the game in different stages, and reminding them about signing up.",
      ],
      GENERAL: [
        "Information about the game can be easily relayed during an office announcement which can be made during a company meeting.",
        "An email can be sent out every week a month leading up to the game to inform them about the game in different stages, and reminding them about signing up.",
      ],
    },
  },
  default: {
    PRIVATE: ["This is the default private option"],
    PUBLIC: ["This is the default public option"],
  },
};
