import React from "react";
import moment from "moment";

import { formatAMPM } from "../../general";

const LogList = ({ logsArr }) => {
  return logsArr.map((log) => {
    if (log.logType === "catch") {
      const {
        catcherInfo: { firstName: catcherFirstName, lastName: catcherLastName },
        targetInfo: { firstName: targetFirstName, lastName: targetLastName },
        logDate,
      } = log;
      return (
        <div className="game-log" key={logDate}>
          <div className="timestamp">
            <p>{moment(logDate).format("DD MMMM")}</p>
            <p className="time">
              {formatAMPM(new Date(logDate)).time}{" "}
              {formatAMPM(new Date(logDate)).ampm}
            </p>
          </div>
          <div className="catch-text-container">
            <p className="catcher-name">
              {catcherFirstName} {catcherLastName}
            </p>
            <p className="caught-text">caught</p>
            <p className="target-name">
              {targetFirstName} {targetLastName}
            </p>
          </div>
        </div>
      );
    }
    if (log.logType === "gameStarted") {
      const { logDate } = log;
      return (
        <div className="game-log" key={logDate}>
          <div className="timestamp">
            <p>{moment(logDate).format("DD MMMM")}</p>
            <p className="time">
              {formatAMPM(new Date(logDate)).time}{" "}
              {formatAMPM(new Date(logDate)).ampm}
            </p>
          </div>
          <p className="simple-log-text">The game started</p>
        </div>
      );
    }
    return null;
  });
};

export default LogList;
