export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const INVITE_PLAYERS = 'INVITE_PLAYER';
export const FETCH_INVITES = 'FETCH_INVITES';
export const FETCH_PLAYERS = 'FETCH_PLAYERS';
export const EDIT_PLAYER = 'EDIT_PLAYER';
export const REMOVE_PLAYER = 'REMOVE_PLAYER';
export const SET_INVITE_TYPE = 'SET_INVITE_TYPE';

export const NEW_ORGANIZER = 'NEW_ORGANIZER';
export const SUBMIT_ONBOARDING_DATA = 'SUBMIT_ONBOARDING_DATA';