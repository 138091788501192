import React from "react";

const MainButton = (props) => {
  const classString = "main-btn " + props.className;
  return (
    <button
      {...props}
      className={classString}
      disabled={props.disabled || props.loading}
      data-loading={props.loading || undefined}
    >
      {props.children}
    </button>
  );
};

export default MainButton;
