import React, { Component } from "react";
import { connect } from "react-redux";

import { invitePlayers } from "../../redux/actions/playerActions";

import { ReactComponent as MailIcon } from "../../assets/icons/mail-icon.svg";
import LearnMoreLink from "../Elements/LearnMoreLink";

class InviteForm extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        formValid: false,
      },
    };
  }

  //   Handle input value change

  handleChange = (event) => {
    event.persist();
    this.setState(
      (state) => ({
        formData: {
          ...state.formData,
          [event.target.name]: event.target.value,
        },
      }),
      () => this.validateForm()
    );
  };

  //   Handle submit

  handleSubmit = (event) => {
    event.preventDefault();
    const { formValid, firstName, lastName, email } = this.state.formData;

    if (!formValid) return;

    this.props
      .invitePlayers([
        {
          firstName,
          lastName,
          email,
        },
      ])
      .then((success) => {
        if (success) {
          alert("Player added! Let's add more :)");
          this.setState((state) => ({
            formData: {
              firstName: "",
              lastName: "",
              email: "",
              formValid: false,
            },
          }));
        } else {
          alert("Something went wrong, please try again...");
          this.setState((state) => ({
            formData: {
              firstName: "",
              lastName: "",
              email: "",
              formValid: false,
            },
          }));
        }
      });
  };

  //   Validate form

  validateForm = () => {
    const { email } = this.state.formData;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let valid = true;

    if (!emailRegex.test(email)) valid = false;

    this.setState((state) => ({
      formData: {
        ...state.formData,
        formValid: valid,
      },
    }));
  };

  render() {
    return (
      <div className="invite-form">
        <h2>Invite players</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="input-wrapper">
            <input
              type="email"
              required
              name="email"
              placeholder="Player email"
              className="email-input"
              onChange={this.handleChange}
              value={this.state.formData.email}
            />
          </div>
          <button
            type="submit"
            className="main-btn main-btn--with-icon"
            disabled={!this.state.formData.formValid}
          >
            <MailIcon /> Send email invite
          </button>
        </form>
        <LearnMoreLink
          linkText="How does this work?"
          title="How do invites work?"
          modalText={
            <p>
              The player will receive an email invite to the game. The invite
              will clearly instruct them how to download the app and how to
              accept the invitation (join the game). You only pay for players
              who have accepted the invitation. They have to accept the
              invitation before you pay for the game.
              <p style={{ fontWeight: "bold", marginTop: 10 }}>
                After payment, the invites are no longer valid.
              </p>
            </p>
          }
        />
      </div>
    );
  }
}

export default connect(null, { invitePlayers })(InviteForm);
