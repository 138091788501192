import React, { Component } from "react";
import { connect } from "react-redux";

import { registerOrganizer } from "../../redux/actions/accountActions";

class RegisterForm extends Component {
  constructor() {
    super();
    this.state = {
      organizationName: "",
      organizationEmail: "",
      password: "",
      error: null,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log("hej");
    try {
      const { organizationName, organizationEmail, password } = this.state;

      await this.props.registerOrganizer({
        organizationName,
        organizationEmail,
        password,
      });
    } catch (err) {
      this.setState((prevState) => ({ ...prevState, error: err }));
    }
  };

  handleChange = (event) => {
    event.persist();
    this.setState(
      (state) => ({
        ...state,
        [event.target.name]: event.target.value,
      }),
      () => this.validateInputForm()
    );
  };

  validateInputForm = () => {
    const { organizationName, organizationEmail, password } = this.state;
    let valid = true;

    if (!organizationName || organizationName == "") valid = false;

    if (!organizationEmail || organizationEmail == "") valid = false;

    if (!password || password == "") valid = false;

    this.setState((state) => ({
      ...state,
      inputFormValid: valid,
    }));
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.error && (
          <div className="form-error">{this.state.error.message}</div>
        )}
        <label>Organization name</label>
        <input
          type="text"
          placeholder="Organization"
          name="organizationName"
          value={this.state.organizationName}
          onChange={this.handleChange}
          required
        />
        <br />
        <label>Organization email</label>
        <input
          type="email"
          placeholder="example@domain.com"
          name="organizationEmail"
          value={this.state.organizationEmail}
          onChange={this.handleChange}
          required
        />
        <br />
        <label>Password</label>
        <input
          type="password"
          placeholder="••••••••••"
          name="password"
          value={this.state.password}
          onChange={this.handleChange}
          required
        />
        <br />
        <button
          type="submit"
          className="submit-btn"
          disabled={!this.state.inputFormValid}
        >
          Register host account
        </button>
      </form>
    );
  }
}

export default connect(null, { registerOrganizer })(RegisterForm);
