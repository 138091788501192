import React from "react";
import { useState } from "react";
import { connect } from "react-redux";

import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import { sendGameMessage } from "../../redux/actions/gameInfoActions";
import LearnMoreLink from "../Elements/LearnMoreLink";

const GameMessageForm = ({ sendGameMessage }) => {
  const [gameMessage, setGameMessage] = useState("");
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    setGameMessage(event.target.value);
  };
  const handleSubmit = async () => {
    if (gameMessage.trim() === "") {
      setError({ message: "Please write a message first" });
      return;
    }
    try {
      await sendGameMessage(gameMessage);
      setError(null);
      setGameMessage("")
      alert("Game message sent!")
    } catch (err) {
      setError({ message: "Something went wrong" });
      console.log(err);
    }
  };
  return (
    <div className="game-message-form">
      <textarea
        placeholder="Write your players a message"
        rows="5"
        value={gameMessage}
        onChange={handleChange}
      />
      {error && <div className="error-message">{error.message}</div>}
      <button className="main-btn main-btn--with-icon" onClick={handleSubmit}>
        <CheckmarkIcon />
        Send to all players
      </button>
      <LearnMoreLink
        linkText="What are game messages?"
        modalText="These are text messages you can send to your players throughout the game. Game messages can be used for anything you want to communicate to the players. You might want to inform about a rule change, acknowledge that the game is coming to an end, or just let them know they look cute."
      />
    </div>
  );
};

export default connect(null, { sendGameMessage })(GameMessageForm);
