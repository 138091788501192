import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";

import RulesOverlay from "../Rules/RulesOverlay";
import EditRulesOverlay from "../Rules/EditRulesOverlay";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import { ReactComponent as PenIcon } from "../../assets/icons/pen-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-icon.svg";
import LearnMoreIcon from "../Elements/LearnMoreIcon";

const SetupPage = (props) => {
  const [targetsNum, setTargetsNum] = useState(1);
  return (
    <div className="setup-page">
      <div className="section-container main-setup-container">
        <h1>Let's set up your game!</h1>

        <div className="setup-step">
          <div className="step-info-wrapper">
            {/* <CheckmarkIcon className="checkmark-icon" /> */}
            <div className="setup-step-info">
              <h2>Game rules</h2>
              <p>
                We have already given you a set of customized rules based on
                your game type. You can change them however you like.
              </p>
            </div>
          </div>
          <div className="setup-step-buttons">
            <Link to="/setup/rules">
              <button className="main-btn main-btn--with-icon main-btn--secondary">
                <EyeIcon />
                Read rules
              </button>
            </Link>
            <Link to="/setup/rules/edit">
              <button className="main-btn main-btn--with-icon main-btn--text">
                <PenIcon />
                Edit rules
              </button>
            </Link>
          </div>
        </div>
        {/* <div className="setup-options">
          <h2>Options</h2>
          <div className="setup-option">
            <h3>Targets per player</h3>
            <LearnMoreIcon
              title="Targets per player"
              modalText="Here is how this works"
            />
            <input
              type="number"
              value={targetsNum}
              onChange={(event) => setTargetsNum(event.target.value)}
              className="number-input"
            />
          </div>
        </div> */}
      </div>
      <Switch>
        <Route
          path="/setup/rules"
          exact
          render={() => (
            <RulesOverlay closeURL="/setup" editURL="/setup/rules/edit" />
          )}
        />
        <Route
          path="/setup/rules/edit"
          exact
          render={() => (
            <EditRulesOverlay postSaveURL="/setup/rules?recentlyUpdated=true" />
          )}
        />
      </Switch>
    </div>
  );
};

export default SetupPage;
