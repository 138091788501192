import React, { Component } from "react";
import { withRouter, NavLink, useLocation } from "react-router-dom";

const PreGameNavbar = () => {
  const activeStyle = {
    borderBottom: "2px solid #FF5C32",
    color: "black",
  };
  const navButtonPath = (location, btn) => {
    const locationName = location.pathname;
    if (btn === "next") {
      if (locationName === "/setup") return "/player-setup";
      if (locationName === "/player-setup") return "/checkout";
      return locationName;
    }
    if (btn === "prev") {
      if (locationName === "/player-setup") return "/setup";
      if (locationName === "/checkout") return "/player-setup";
      return locationName;
    }
  };
  const navButtonClass = (location, btn) => {
    if (btn === "next") {
      return location.pathname === "/checkout"
        ? "nav-btn nav-btn--disabled"
        : "nav-btn";
    }
    if (btn === "prev") {
      return location.pathname === "/setup"
        ? "nav-btn nav-btn--disabled"
        : "nav-btn";
    }
  };
  return (
    <div className="pregame-nav-container">
      <div className="pregame-nav">
        {/* Will be changed to SVG */}
        <NavLink className="link" to="/setup" activeStyle={activeStyle}>
          <div className="link-text">
            <span>1</span> Setup
          </div>
        </NavLink>
        <NavLink className="link" to="/player-setup" activeStyle={activeStyle}>
          <div className="link-text">
            <span>2</span> Add players
          </div>
        </NavLink>
        <NavLink className="link" to="/checkout" activeStyle={activeStyle}>
          <div className="link-text">
            <span>3</span> Pay and start
          </div>
        </NavLink>
      </div>
      <div className="nav-buttons">
        <NavLink
          to={navButtonPath(useLocation(), "prev")}
          className={navButtonClass(useLocation(), "prev")}
        >
          Back
        </NavLink>
        <NavLink
          to={navButtonPath(useLocation(), "next")}
          className={navButtonClass(useLocation(), "next")}
        >
          Next
        </NavLink>
      </div>
    </div>
  );
};

export default withRouter(PreGameNavbar);
