import React, { Component } from "react";
import watermarkLogo from "../../assets/logo/logo-full-lightgrey.svg";

import { connect } from "react-redux";
import { submitOnboardingData } from "../../redux/actions/gameInfoActions";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import { Redirect } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";

class AccountSetup extends Component {
  constructor() {
    super();

    // State
    this.state = {
      currentStep: 0,
      formData: {
        gameEnvironment: "school",
        gameEnvironmentName: "",
        location: "",
        potPlayers: "",
        eventDuration: "",
        subGameEnvironment: "",
      },
      inputFormValid: false,
      inputFormError: false,
    };
  }

  // Handle input change

  handleChange = (event) => {
    event.persist();
    this.setState(
      (state) => ({
        formData: {
          ...state.formData,
          [event.target.name]: event.target.value,
        },
      }),
      () => this.validateInputForm()
    );
  };

  // Handle location change

  handleLocationSelection = (location) => {
    this.setState(
      (state) => ({
        formData: {
          ...state.formData,
          location: location,
        },
      }),
      () => this.validateInputForm()
    );
  };

  // Get form content based on gameEnvironment

  getFormContents = () => {
    switch (this.state.formData.gameEnvironment) {
      case "school":
        return {
          heading: "Tell us a little about the school",
          nameInput: "Name of the school",
          locationInput: "Location of the school (city)",
          potPlayersInput: "Total number of students at the school",
          subGameEnvironmentInput: "School type",
        };
        break;
      case "camp":
        return {
          heading: "Tell us a little about the camp",
          nameInput: "Name of the camp",
          locationInput: "Location of the camp (city)",
          potPlayersInput: "Number of participants",
          eventDurationInput: "Camp duration (days)",
        };
        break;
      case "event":
        return {
          heading: "Tell us a little about the event/conference",
          nameInput: "Name of the event/conference",
          locationInput: "Location of the event/conference (city)",
          potPlayersInput: "Number of attendants",
        };
        break;
      case "office":
        return {
          heading: "Tell us a little about the workplace",
          nameInput: "Name of the company",
          locationInput: "Office location (city)",
          potPlayersInput: "Number of co-workers at the office",
        };
      default:
        return {};
        break;
    }
  };

  // Validate input form

  validateInputForm = () => {
    const {
      gameEnvironmentName,
      location,
      potPlayers,
      eventDuration,
      subGameEnvironment,
    } = this.state.formData;
    const {
      nameInput,
      locationInput,
      potPlayersInput,
      eventDurationInput,
      subGameEnvironmentInput,
    } = this.getFormContents();
    let valid = true;

    if (nameInput && (!gameEnvironmentName || gameEnvironmentName == "")) {
      valid = false;
    }

    if (locationInput && (!location || location == "")) {
      valid = false;
    }

    if (potPlayersInput && (!potPlayers || potPlayers == "")) {
      valid = false;
    }

    if (eventDurationInput && (!eventDuration || eventDuration == "")) {
      valid = false;
    }
    if (
      subGameEnvironmentInput &&
      (!subGameEnvironment || subGameEnvironment == "")
    ) {
      valid = false;
    }
    this.setState((state) => ({
      ...state,
      inputFormValid: valid,
    }));
  };

  // Change step (passed down in props). If data needs to be submitted that
  // doesn't come from any type of input, it can be done as a second argument

  changeStep = (action, data = null) => {
    if (action == "next") {
      this.setState((state) => ({ currentStep: state.currentStep + 1 }));
    } else if (action == "prev") {
      this.setState((state) => ({ currentStep: state.currentStep - 1 }));
    }
    if (data) {
      this.setState((state) => ({
        formData: {
          ...state.formData,
          ...data,
        },
      }));
    }
  };

  submitForm = () => {
    console.log("submitting");
    let {
      gameEnvironment,
      gameEnvironmentName,
      location,
      potPlayers,
      subGameEnvironment,
    } = this.state.formData;

    let { inputFormValid } = this.state;

    // dispatch redux action

    if (gameEnvironment !== "school") subGameEnvironment = "general";

    if (inputFormValid) {
      console.log("sending data");
      this.props
        .submitOnboardingData({
          gameEnvironment,
          gameEnvironmentName,
          location,
          potPlayers,
          subGameEnvironment,
        })
        .then((success) => {
          if (success) {
            this.changeStep("next");
          }
        })
        .catch((err) => {
          this.setState((state) => ({
            ...state,
            error: {
              code: err.code,
              message: err.message,
            },
          }));
        });
    }
  };

  // Render step based on state.currentStep

  renderStep = () => {
    const {
      gameEnvironment,
      gameEnvironmentName,
      location,
      potPlayers,
      subGameEnvironment,
    } = this.state.formData;

    const { inputFormValid, inputFormError } = this.state;
    const values = {
      gameEnvironmentName,
      location,
      potPlayers,
      subGameEnvironment,
    };
    switch (this.state.currentStep) {
      case 0:
        return <FirstStep changeStep={this.changeStep} />;
        break;
      case 1:
        return <SecondStep changeStep={this.changeStep} />;
        break;
      case 2:
        return (
          <ThirdStep
            changeStep={this.changeStep}
            handleChange={this.handleChange}
            handleLocationSelection={this.handleLocationSelection}
            submitForm={this.submitForm}
            gameEnvironment={gameEnvironment}
            values={values}
            formContents={this.getFormContents()}
            formValid={inputFormValid}
            formError={inputFormError}
          />
        );
        break;
      case 3:
        return <FourthStep />;
    }
  };

  redirectCheck = () => {
    const { auth, profile } = this.props;
    console.log(profile);
    if (!auth.uid) return <Redirect to="register" />;
    else if (isLoaded(profile) && profile.gameEnvironment)
      return <Redirect to="/" />;
  };

  // Render

  render() {
    if (isLoaded(this.props.profile)) {
      return (
        <div className="onboarding-page">
          {this.redirectCheck()}
          {this.renderStep()}
          <img
            className="watermark-logo"
            src={watermarkLogo}
            alt="Catcher Games"
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  return {
    auth,
    profile,
  };
};

export default connect(mapStateToProps, { submitOnboardingData })(AccountSetup);
