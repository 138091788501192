import React from "react";

const FirstStep = ({ changeStep }) => {
  return (
    <div className="first-step">
      <h3 className="secondary-heading">Welcome to Catcher Games!</h3>
      <h1 className="primary-heading">
        To get you started, we would like to get to know you a little
      </h1>
      <button
        className="primary-button"
        onClick={() => {
          changeStep("next");
        }}
      >
        Sure! Let's go
      </button>
    </div>
  );
};

export default FirstStep;
