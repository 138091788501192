import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";

class JoinedPlayersInfo extends Component {
  render() {
    const { playersNum } = this.props;
    return (
      <div className="player-list-info joined-players">
        <h2>Joined players</h2>
        <div className="player-list-amount">
          Total players
          <span>{playersNum}</span>
        </div>
        <Link to="/player-setup/players" className="player-list-link">
          See all
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const playersNum = game.playersNum || 0;
  return {
    auth,
    profile,
    playersNum,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [{ collection: "games", doc: props.profile.currentGameId }])
)(JoinedPlayersInfo);
