import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import moment from "moment";

import OverlayPage from "../Global/OverlayPage";
import PlayerList from "./PlayerList/PlayerList";

const InviteListOverlay = (props) => {
  const goBack = () => {
    props.history.push("/player-setup");
  };
  return (
    <OverlayPage toggleOverlay={goBack}>
      <div className="list-overlay">
        <h2>Pending invites</h2>
        <div className="player-list-amount">
          Total pending invites<span>{props.pendingPlayerInvitesNum}</span>
        </div>
        {isLoaded(props.invitesObj) ? (
          props.invitesObj ? (
            <PlayerList players={props.invitesArr} />
          ) : (
            <p>No pending invites</p>
          )
        ) : (
          <p>Loading invites</p>
        )}
      </div>
    </OverlayPage>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const pendingPlayerInvitesNum = game.pendingPlayerInvitesNum || 0;
  const invitesObj = state.firestore.data.playerInvites || null;
  const invitesArr = invitesObj
    ? Object.keys(invitesObj)
        .map((inviteId) => ({
          id: inviteId,
          ...invitesObj[inviteId],
          formatedInviteDate: moment(
            invitesObj[inviteId].inviteDate
          ).calendar(),
        }))
        .sort((a, b) => b.inviteDate - a.inviteDate)
    : null;
  return {
    auth,
    profile,
    invitesObj: state.firestore.data.playerInvites,
    invitesArr,
    pendingPlayerInvitesNum,
  };
};

// TODO firestoreConnect subcollection

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "games",
      doc: props.profile.currentGameId || "filler",
      subcollections: [{ collection: "playerInvites" }],
      where: ["pending", "==", true],
      // orderBy: ["inviteDate", "desc"],
      storeAs: "playerInvites",
    },
  ])
)(InviteListOverlay);
