import React, { Component } from "react";
import { connect } from "react-redux";

import PendingInvitesInfo from "./PendingInvitesInfo"
import JoinedPlayersInfo from "./JoinedPlayersInfo"

class PlayerListSection extends Component {
  render() {
    return (
      <div className="section-container player-list-container">
        {this.props.inviteType === "PRIVATE" ? <PendingInvitesInfo /> : null}
        {<JoinedPlayersInfo />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inviteType: state.playerInfo.inviteType,
});

export default connect(mapStateToProps)(PlayerListSection);
