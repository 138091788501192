import React, { Component } from "react";

export default class ProgressRing extends Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }
  render() {
    let { radius, stroke, progress } = this.props;
    progress = progress ? progress - 0.9 : 0;

    const strokeDashoffset =
      this.circumference - (progress / 100) * this.circumference;
    return (
      <svg
        className="progress-ring"
        height={radius * 2}
        width={radius * 2}
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          stroke="lightgrey"
          fill="transparent"
          strokeWidth={stroke / 2}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke="black"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + " " + this.circumference}
          style={{ strokeDashoffset, strokeLinecap: "round" }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    );
  }
}
