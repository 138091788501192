import React from "react";
import { useState } from "react";
import { postData } from "../../general";
import MainButton from "../Elements/MainButton";
import DefaultModal from "../Global/DefaultModal";

const DiscountCodeSection = () => {
  const [discountCode, setDiscountCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const validInput = discountCode.trim() !== "";

  const claimDiscount = async () => {
    setErrorMessage(null);
    setLoading(true);
    const response = await postData("/claimDiscount", {
      discountCode: discountCode,
    });
    const responseMessage = (await response.json()).message;
    if (response.ok) {
      setDiscountCode("");
      setSuccessMessage(responseMessage);
      toggleModal();
    } else {
      setErrorMessage(responseMessage);
    }
    setLoading(false);
  };

  const toggleModal = () => {
    setShowModal((currentState) => !currentState);
  };
  return (
    <>
      <DefaultModal showModal={showModal} toggleModal={toggleModal}>
        <h1>Discount code applied</h1>
        <p>{successMessage}</p>
        <MainButton onClick={toggleModal}>Close</MainButton>
      </DefaultModal>
      <div className="section-container discount-code-section">
        <h2>Discount code</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            claimDiscount();
          }}
        >
          <input
            placeholder="Enter discount code"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
          />
          <MainButton type="submit" disabled={!validInput} loading={loading}>
            Apply
          </MainButton>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </>
  );
};

export default DiscountCodeSection;
