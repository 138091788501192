import React from "react";
import closeIcon from "../../assets/icons/close-icon.svg";

const DefaultModal = ({ showModal, toggleModal, options = {}, children }) => {
  if (!showModal) return null;
  return (
    <div className="modal__page-overlay">
      <div className="modal__box default-modal">
        {options.hideCloseIcon ? (
          ""
        ) : (
          <img
            src={closeIcon}
            alt="close"
            className="close-icon"
            onClick={toggleModal}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default DefaultModal;
