import {
  SET_INVITE_TYPE,
  FETCH_PLAYERS,
  INVITE_PLAYERS,
  REMOVE_PLAYER,
  SHOW_LOADER,
  HIDE_LOADER,
} from "./types";
import { db, postData } from "../../general";


export const setInviteType = (inviteType) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  // Send data to DB

  setTimeout(() => {
    const payload = inviteType;

    dispatch({
      type: SET_INVITE_TYPE,
      payload,
      error: null,
    });
    dispatch({ type: HIDE_LOADER });
  }, 1000);
};

export const invitePlayers = (players) => (dispatch, getState, getFirebase) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: SHOW_LOADER });

    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const { auth } = getState().firebase;

    const currentMs = new Date().getTime();

    let playersAdded = 0;

    players.forEach((player, idx) => {
      // TODO add players to firestore collection
      const { firstName, lastName, email } = player;
      const gameId = getState().firebase.profile.currentGameId;
      const postBody = {
        uid: auth.uid,
        gameId,
        email,
      };

      postData("/invitePlayer", postBody).then((inviteId) => {
        if (idx === players.length - 1) {
          dispatch({ type: HIDE_LOADER });
          resolve(true);
        }
      });
    });
  });
};
