import React, { useState, useEffect } from "react";

import PlayerList from "./PlayerList/PlayerList";
import spinnerGif from "../../assets/gifs/grey-spinner.gif";
import { ReactComponent as PlusIcon } from "../../assets/icons/small-plus-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/small-close-icon.svg";
import { ReactComponent as UpIcon } from "../../assets/icons/small-up-arrow-icon.svg";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import moment from "moment";
import { compareNames, postData } from "../../general";
import DefaultModal from "../Global/DefaultModal";

const EditPlayer = ({ player = {}, toggleModal, submitPlayerEdits }) => {
  const [newFirstName, setNewFirstName] = useState(player.firstName);
  const [newLastName, setNewLastName] = useState(player.lastName);
  const [newPoints, setNewPoints] = useState(player.points);
  const [newlivesNum, setNewlivesNum] = useState(player.lives);
  const [errorText, setErrorText] = useState();

  const editPoints = (increase = 0) =>
    setNewPoints((prevState) => {
      const newPoints = prevState + increase;
      if (newPoints > 0) return newPoints;
      else return 0;
    });

  const deltaPoints = newPoints - player.points;
  const deltaLives = newlivesNum - player.lives;

  const changesMade = !(
    newFirstName == player.firstName &&
    newLastName == player.lastName &&
    deltaPoints == 0 &&
    deltaLives == 0
  );

  const createError = (message) => {
    setErrorText(message);
    setTimeout(() => setErrorText(null), 3000);
  };

  const applyChanges = () => {
    setErrorText(null);
    if (newFirstName.trim() == "") createError("Please provide a first name");
    else if (newLastName.trim() == "")
      createError("Please provide a last name");
    else if (
      newPoints < 0 ||
      (player.alive && newlivesNum < 1) ||
      (!player.alive && newlivesNum > 0)
    )
      createError("Something went wrong, try reloading the page");
    else {
      const newPlayerInfo = {
        playerInstanceId: player.playerInstanceId,
        firstName: newFirstName.trim(),
        lastName: newLastName.trim(),
        points: newPoints,
        lives: newlivesNum,
      };
      submitPlayerEdits(newPlayerInfo);
    }
  };

  return (
    <div className="edit-player-container">
      <h2>Edit player</h2>
      <div className="input-column-section">
        <div className="input-column">
          <label>First name</label>
          <input
            value={newFirstName}
            onChange={(e) => setNewFirstName(e.target.value)}
          />
        </div>
        <div className="input-column">
          <label>Last name</label>
          <input
            value={newLastName}
            onChange={(e) => setNewLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="points-section">
        <label>Points</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="points-counter">{newPoints}</div>
          <div className="points-counter-input">
            <button onClick={() => editPoints(50)}>
              <UpIcon width={8} />
            </button>
            <button onClick={() => editPoints(-50)}>
              <UpIcon width={8} />
            </button>
          </div>
          {deltaPoints !== 0 && (
            <div
              className={
                "delta-counter " +
                (Math.sign(deltaPoints) === 1 ? "increase" : "decrease")
              }
            >
              {Math.sign(deltaPoints) === 1 && "+"}
              {deltaPoints}
            </div>
          )}
        </div>
      </div>
      {player.alive && (
        <div className="lives-section">
          <label>Remaining lives</label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="lives-counter">{newlivesNum}</div>
            <input
              type="range"
              min="1"
              max="10"
              value={newlivesNum}
              onChange={(e) => setNewlivesNum(e.target.value)}
            />
            {deltaLives !== 0 && (
              <div
                className={
                  "delta-counter " +
                  (Math.sign(deltaLives) === 1 ? "increase" : "decrease")
                }
              >
                {Math.sign(deltaLives) === 1 && "+"}
                {deltaLives}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="buttons-container">
        <button
          className="main-btn main-btn--with-icon"
          disabled={!changesMade}
          onClick={applyChanges}
        >
          <CheckmarkIcon />
          Apply changes
        </button>
        <button
          className="main-btn main-btn--with-icon main-btn--secondary discard-btn"
          onClick={toggleModal}
        >
          <CloseIcon />
          Discard changes
        </button>
        {errorText && <div className="error-text">{errorText}</div>}
      </div>
    </div>
  );
};

const PlayersPage = ({ playersArr, playersObj }) => {
  const [sortedPlayersArr, setSortedPlayersArr] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [editPlayerModalStatus, setEditPlayerModalStatus] = useState("DEFAULT");
  const [currentEditablePlayer, setCurrentEditablePlayer] = useState({});
  const [showEditPlayerModal, setShowEditPlayerModal] = useState(false);
  const toggleEditPlayerModal = () => {
    setEditPlayerModalStatus("DEFAULT");
    setShowEditPlayerModal((prevState) => !prevState);
  };

  const editPlayer = (player) => {
    if (typeof player == "object") {
      setCurrentEditablePlayer(player);
      setShowEditPlayerModal(true);
    }
  };

  const submitPlayerEdits = async (playerInfo) => {
    setEditPlayerModalStatus("SUBMITTING");
    const response = await postData("/editPlayerInfo", playerInfo);
    if (!response.ok) {
      setEditPlayerModalStatus("ERROR");
      console.log((await response.json()).message);
    } else {
      setTimeout(() => {
        setEditPlayerModalStatus("SUCCESS");
        setCurrentEditablePlayer({});
      }, 0);
    }
  };

  const searchPlayer = (searchInput) => {
    setSearchText(searchInput);
    if (searchInput.trim() != "") {
      let data = playersArr.filter((player) =>
        new RegExp(searchInput.replace(/\s+/g, "").toLowerCase()).test(
          (player.firstName + player.lastName).replace(/\s+/g, "").toLowerCase()
        )
      );
      setSortedPlayersArr(data);
    } else {
      setSortedPlayersArr(playersArr);
    }
  };

  useEffect(() => searchPlayer(searchText), [playersArr]);

  return (
    <div className="players-page">
      <DefaultModal
        showModal={showEditPlayerModal}
        toggleModal={toggleEditPlayerModal}
        options={{ hideCloseIcon: editPlayerModalStatus === "SUBMITTING" }}
      >
        {editPlayerModalStatus === "SUCCESS" && (
          <div className="response-container">
            <div style={{ fontSize: "14px" }}>Player successfully updated</div>
            <button className="main-btn" onClick={toggleEditPlayerModal}>
              Close
            </button>
          </div>
        )}
        {editPlayerModalStatus === "ERROR" && (
          <div className="response-container">
            <div style={{ fontSize: "14px" }}>
              Something went wrong. Please try to reload the page and try again!
            </div>
            <button className="main-btn" onClick={toggleEditPlayerModal}>
              Close
            </button>
          </div>
        )}
        {editPlayerModalStatus === "SUBMITTING" && (
          <div className="loading-container">
            <img width="20" src={spinnerGif} />
            <div style={{ fontSize: "14px" }}>Updating player info</div>
          </div>
        )}
        {editPlayerModalStatus === "DEFAULT" && (
          <EditPlayer
            player={currentEditablePlayer}
            toggleModal={toggleEditPlayerModal}
            submitPlayerEdits={submitPlayerEdits}
          />
        )}
      </DefaultModal>
      <div className="actions-container">
        <button
          className="main-btn main-btn--with-icon main-btn--secondary"
          onClick={() => {
            alert(
              "Please email us at support@catchergames.com regarding this feature"
            );
          }}
        >
          <PlusIcon />
          Add players
        </button>
        <div className="search-field-wrapper">
          <input
            className="search-field"
            placeholder="Search player"
            value={searchText}
            onChange={(event) => searchPlayer(event.target.value)}
          />
          <SearchIcon className="search-icon" />
        </div>
      </div>
      <div className="section-container">
        {isLoaded(playersObj) && sortedPlayersArr[0] && (
          <PlayerList
            players={sortedPlayersArr}
            gameStarted={true}
            className="full-page-width"
            functions={{ editPlayer }}
          />
        )}
        {searchText.trim() !== "" && sortedPlayersArr.length < 1 && (
          <p className="no-search-results">No search results</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const playersNum = game.playersNum || 0;
  const playersObj = state.firestore.data.playerInstances || null;
  let playersArr = playersObj
    ? Object.keys(playersObj).map((inviteId) => playersObj[inviteId])
    : [];

  playersArr.sort(compareNames);
  playersArr.sort((a, b) => {
    if (a.alive && b.alive) return b.points - a.points;
    else if (!a.alive && !b.alive) return b.eliminatedDate - a.eliminatedDate;
    else if (a.alive) return -1;
    else return 1;
  });

  playersArr = playersArr.map((player, i) => ({ ...player, rank: i + 1 }));

  return {
    auth,
    profile,
    playersObj: state.firestore.data.playerInstances,
    playersArr,
    playersNum,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "games",
      doc: props.profile.currentGameId || "filler",
      subcollections: [{ collection: "playerInstances" }],
      orderBy: ["joinedDate", "desc"],
      storeAs: "playerInstances",
    },
  ])
)(PlayersPage);
