import React from "react";
import moment from "moment";

const GameListItem = ({ game, index }) => {
  const { startDate, endDate, playersNum, isTestGame } = game;
  const gameDays = parseInt((startDate - endDate) / (24 * 60 * 60 * 1000));
  return (
    <div className="game-list-item">
      <div className="info-container">
        <div className="date-container">
          <p>{moment(startDate).format("Y")}</p>
          <p>
            {moment(startDate).format("D MMM")} -{" "}
            {moment(endDate).format("D MMM")}
          </p>
        </div>
        <p>{playersNum} players</p>
      </div>
      <div className="badge-container">
        {isTestGame && <div className="badge grey">Trial game</div>}
        {index === 0 && <div className="badge green">Last game</div>}
      </div>
    </div>
  );
};

export default GameListItem;
