import React from "react";
import { Link, withRouter } from "react-router-dom";

import OverlayPage from "../Global/OverlayPage";
import RulesDisplay from "./RulesDisplay";
import { ReactComponent as PenIcon } from "../../assets/icons/pen-icon.svg";

const RulesOverlay = (props) => {
  const { closeURL = "/setup", editURL = "/setup/rules/edit" } = props;
  const closeOverlay = () => {
    props.history.push(closeURL);
  };
  return (
    <OverlayPage toggleOverlay={closeOverlay}>
      <div className="rules-overlay">
        <div className="rules-container">
          <Link to={editURL} className="edit-btn-wrapper">
            <button className="main-btn main-btn--with-icon main-btn--secondary edit-btn">
              <PenIcon />
              Edit rules
            </button>
          </Link>
          <RulesDisplay />
        </div>
      </div>
    </OverlayPage>
  );
};

export default withRouter(RulesOverlay);
