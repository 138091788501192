import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as PenIcon } from "../../assets/icons/pen-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/small-close-icon.svg";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import DefaultModal from "../Global/DefaultModal";
import { useState } from "react";

import { updateGameOptions } from "../../redux/actions/gameInfoActions";

const EditGameOptions = ({
  gameOptions,
  toggleOptionsModal,
  submitGameOptions,
}) => {
  const [newTargetsNum, setNewTargetsNum] = useState(
    gameOptions.targetsNum || 1
  );
  const applyChanges = () => {
    if (newTargetsNum != gameOptions.targetsNum) {
      const newOptions = {
        targetsNum: newTargetsNum,
        livesNum: 1,
      };
      submitGameOptions(newOptions);
    }
  };
  return (
    <div className="edit-game-options-container">
      <h2>Edit game options</h2>
      <table className="edit-game-options-table">
        <tbody>
          <tr>
            <td>
              <div>Targets per player</div>
            </td>
            <td>
              <div className="new-targets-counter">{newTargetsNum}</div>
              <input
                type="range"
                min="1"
                max="10"
                value={newTargetsNum}
                onChange={(event) => setNewTargetsNum(event.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Lives per player</td>
            <td>
              <div className="new-targets-counter">{gameOptions.livesNum}</div>
              <div className="option-info">Preset</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="button-container">
        <button
          className="main-btn main-btn--with-icon"
          disabled={newTargetsNum == gameOptions.targetsNum}
          onClick={applyChanges}
        >
          <CheckmarkIcon />
          Apply changes
        </button>
        <button
          className="main-btn main-btn--with-icon main-btn--secondary discard-btn"
          onClick={toggleOptionsModal}
        >
          <CloseIcon />
          Discard changes
        </button>
      </div>
      <p className="explainer-text">
        When you click <span style={{ fontWeight: "bold" }}>apply changes</span>
        , all targets will be shuffled and every remaining player will have{" "}
        {newTargetsNum} target{newTargetsNum != 1 && "s"}.
      </p>
    </div>
  );
};

const RulesSection = ({ lastEditedDate }) => {
  return (
    <div className="section-container">
      <div className="game-rules-container">
        <div className="game-rules-info">
          <h2>Game rules</h2>
          <p>
            Last updated:{" "}
            {lastEditedDate ? moment(lastEditedDate).format("D MMM Y") : "---"}
          </p>
        </div>
        <div className="game-rules-buttons">
          <Link to="/game-settings/game-rules">
            <button className="main-btn main-btn--with-icon main-btn--secondary">
              <EyeIcon />
              Read rules
            </button>
          </Link>
          <Link to="/game-settings/game-rules/edit">
            <button className="main-btn main-btn--with-icon main-btn--text">
              <PenIcon />
              Edit rules
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const GameOptionsSection = ({ gameOptions, editOptions }) => {
  return (
    <div className="section-container">
      <div className="game-options-container">
        <div className="game-options-header">
          <h2>Game options</h2>
          <button
            className="main-btn main-btn--with-icon main-btn--text"
            onClick={editOptions}
          >
            <PenIcon />
            Edit options
          </button>
        </div>
        <table className="game-options-table">
          <tbody>
            <tr>
              <td>Targets per player</td>
              <td>{gameOptions.targetsNum}</td>
            </tr>
            <tr>
              <td>Lives per player</td>
              <td>{gameOptions.livesNum}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const GameSettingsPage = ({
  gameOptions,
  rulesLatestUpdated,
  updateGameOptions,
}) => {
  const [showEditOptionsModal, setShowEditOptionsModal] = useState(false);
  const toggleOptionsModal = () =>
    setShowEditOptionsModal((prevState) => !prevState);
  const submitGameOptions = async (newOptions) => {
    try {
      await updateGameOptions(newOptions);
      alert("Game options have been updated!");
      toggleOptionsModal();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="game-settings-page column-wrapper">
      <DefaultModal
        showModal={showEditOptionsModal}
        toggleModal={toggleOptionsModal}
      >
        <EditGameOptions
          gameOptions={gameOptions}
          toggleOptionsModal={toggleOptionsModal}
          submitGameOptions={submitGameOptions}
        />
      </DefaultModal>
      <div className="column column--left">
        <RulesSection lastEditedDate={rulesLatestUpdated} />
        <GameOptionsSection
          gameOptions={gameOptions}
          editOptions={toggleOptionsModal}
        />
      </div>
      <div className="column column--right"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const gameOptions = game.gameOptions || {};
  const rulesObj = state.firestore.data.rules || {};
  const rulesLatestUpdated = rulesObj.latestUpdated;
  return {
    profile,
    gameOptions,
    rulesLatestUpdated,
  };
};

export default compose(
  connect(mapStateToProps, { updateGameOptions }),
  firestoreConnect((props) => [
    {
      collection: "games",
      doc: props.profile.currentGameId,
      subcollections: [{ collection: "otherGameInfo", doc: "rules" }],
      storeAs: "rules",
    },
  ])
)(GameSettingsPage);
