import * as firebase from "firebase";

// FUNCTIONS

const apiURL = (options) =>
  options.dev === true
    ? `http://localhost:${
        options.devPort || 5000
      }/killergame-set/europe-west2/api`
    : "https://europe-west2-killergame-set.cloudfunctions.net/api";

export const postData = async (path = "", body = {}, options = {}) => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  const authorizationHeader = idToken
    ? { authorization: `Bearer ${idToken}` }
    : null;

  const response = await fetch(apiURL(options) + path, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
    body: JSON.stringify(body),
  });
  return response;
};

export const getData = async (path = "", options = {}) => {
  try {
    const idToken = await firebase.auth().currentUser.getIdToken(true);
    const authorizationHeader = idToken
      ? { authorization: `Bearer ${idToken}` }
      : null;

    console.log(apiURL(options));

    const response = await fetch(apiURL(options) + path, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        ...authorizationHeader,
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const compareNames = (a, b) => {
  if (
    `${a.firstName} ${a.lastName}`.toLowerCase() >
    `${b.firstName} ${b.lastName}`.toLowerCase()
  )
    return 1;
  else if (
    `${b.firstName} ${b.lastName}`.toLowerCase() >
    `${a.firstName} ${a.lastName}`.toLowerCase()
  )
    return -1;
  else return 0;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Object to array

export const objToArr = (obj = {}, idKey = "id") =>
  Object.keys(obj).map((key) => ({ [idKey]: key, ...(obj[key] || {}) }));

// Time based functions

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var time = hours + ":" + minutes;
  return { time, ampm };
};

export const daysSince = (date) =>
  parseInt((new Date().getTime() - date) / (24 * 60 * 60 * 1000));

// CONSTANTS

export const minPlayersAmount = 3;
export const freeGamePlayerLimit = 10;

export const playerPriceInSEK = 20;

export const playerPriceInUSD = 3;
