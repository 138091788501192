import React from "react";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";
import moment from "moment";
import LogList from "./LogList";
import { daysSince } from "../../general";

const logsAmount = 15;

const GameLog = ({ catchesObj, logsArr }) => {
  let daysWithoutActivity = 0;
  if (isLoaded(catchesObj)) {
    // Sorting logs based on time and only using the 10 latest
    logsArr.sort((a, b) => b.logDate - a.logDate);
    logsArr = logsArr.slice(0, logsAmount);
    daysWithoutActivity = daysSince(logsArr[0].logDate || new Date().getTime());
  }
  return (
    <div className="game-log-container">
      {daysWithoutActivity > 0 && (
        <p className="daysWithoutActivity">
          Days without activity: {daysWithoutActivity}
        </p>
      )}
      {isLoaded(catchesObj) &&
        (logsArr[0] ? (
          <LogList logsArr={logsArr} />
        ) : (
          <p className="no-activity-text">No game activity</p>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const { startDate } = game;
  const catchesObj = state.firestore.data.catches || null;
  let logsArr = catchesObj
    ? Object.keys(catchesObj).map((catchId) => ({
        ...catchesObj[catchId],
        logType: "catch",
        logDate: catchesObj[catchId].catchDate,
        formatedCatchDate: moment(catchesObj[catchId].catchDate).calendar(),
      }))
    : [];
  logsArr.push({ logType: "gameStarted", logDate: startDate });
  return {
    auth,
    profile,
    startDate,
    catchesObj: state.firestore.data.catches,
    logsArr,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "games",
      doc: props.profile.currentGameId || "filler",
      subcollections: [{ collection: "catches" }],
      orderBy: ["catchDate", "desc"],
      limit: logsAmount,
      storeAs: "catches",
    },
  ])
)(GameLog);
