import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import PreGameUI from "./PreGameUI";
import GameStartedUI from "./GameStartedUI";

class Dashboard extends Component {
  constructor() {
    super();
  }

  redirectCheck = () => {
    const { auth, profile } = this.props;
    if (!auth.uid) return <Redirect to="register" />;
    else if (isLoaded(profile)) {
      if (!profile.gameEnvironment) return <Redirect to="onboarding" />;
      if (!profile.currentGameId) return <Redirect to="games" />;
    }
  };
  render() {
    const { gameStarted, profile } = this.props;
    return (
      <div>
        {isLoaded(profile) ? (
          gameStarted ? (
            <GameStartedUI />
          ) : (
            <PreGameUI match={this.props.match} />
          )
        ) : (
          <p>Loading...</p>
        )}
        {this.redirectCheck()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const gameStarted = game.ongoing;
  return {
    auth,
    profile,
    gameStarted,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(Dashboard);
