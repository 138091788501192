import React from "react";
import { connect } from "react-redux";
import { shuffleTargets } from "../../redux/actions/gameEvents";
import { endGame } from "../../redux/actions/gameInfoActions";
import LearnMoreLink from "../Elements/LearnMoreLink";

const shuffleIconRef = require("../../assets/images/shuffle-icon-white.png");

const GameEventCard = ({
  variant = "default",
  variantClass = "",
  imgSrc,
  title,
  description,
  btnText = "Activate now",
  onBtnClick = () => {
    alert("hello");
  },
}) => {
  if (variant === "placeholder") {
    const placeholderClass = "game-event-card placeholder " + variantClass;
    return (
      <div className={placeholderClass}>
        <p>Coming soon</p>
      </div>
    );
  } else {
    let cardClass = "game-event-card " + variantClass;
    if (variant === "featured") cardClass += " featured";

    let btnClass = "main-btn ";
    if (variant === "default") btnClass += "main-btn--secondary";
    if (variant === "featured") btnClass += "main-btn--white";

    return (
      <div className={cardClass}>
        <div className="card-content">
          {imgSrc && (
            <div className="img-wrapper">
              <img src={imgSrc} />
            </div>
          )}
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <button className={btnClass} onClick={onBtnClick}>
          {btnText}
        </button>
      </div>
    );
  }
};

const GameEventsPage = ({ endGame, shuffleTargets }) => {
  const shuffleTargetsNow = async () => {
    try {
      await shuffleTargets();
      alert("All targets have been shuffled!");
    } catch (err) {
      alert(err);
    }
  };

  // End game
  const endGamePrompt = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to end the game PERMANENTLY?"
    );
    if (confirmation) {
      try {
        await endGame();
      } catch (err) {
        alert("Something went wrong");
        console.log(err);
      }
    }
  };

  return (
    <div className="game-events-page">
      <div className="game-events-grid">
        <GameEventCard
          title="Shuffle targets"
          description="All players receive a new target instantly"
          imgSrc={shuffleIconRef}
          variant="featured"
          variantClass="shuffle-targets"
          btnText="Shuffle now"
          onBtnClick={shuffleTargetsNow}
        />
        <GameEventCard variant="placeholder" variantClass="double-points" />
        <GameEventCard
          variant="placeholder"
          variantClass="revenge-of-the-fallen"
        />
        <GameEventCard variant="placeholder" variantClass="force-catches" />
        <div className="game-event-card">
          <div>
            <h2 style={{ marginBottom: 20 }}>End game permanently</h2>
          </div>
          <button
            className="main-btn main-btn--error"
            style={{ marginBottom: 10 }}
            onClick={endGamePrompt}
          >
            End game
          </button>
          <LearnMoreLink
            linkText="How does this work?"
            modalText={
              <p>
                Normally, the game ends automatically when there's only one
                player left. However, in some cases you might want to end the
                game earlier, and that's when you end the game manually.
                <p style={{ fontWeight: "bold" }}>
                  Note that this action permanently ends the game.
                </p>
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, { endGame, shuffleTargets })(GameEventsPage);
