import React from "react";
import closeIcon from "../../assets/icons/close-icon.svg";

const OverlayPage = (props) => {
  return (
    <div className="overlay-page">
      <img src={closeIcon} alt="close" className="close-icon" onClick={props.toggleOverlay} />
      {props.children}
    </div>
  );
};

export default OverlayPage
{
  /* <OverlayPage toggle={this.toggleOverlay}>
    <h1>information</h1>
</OverlayPage> */
}
