import React from "react";

import ProgressRing from "../Elements/ProgressRing";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { daysSince } from "../../general";

const GameStats = (props) => {
  const {
    alivePlayersNum = 0,
    catchesNum = 0,
    playersNum = 0,
    startDate = 0,
  } = props;
  const gameDays = daysSince(startDate);
  const progressDecimal = alivePlayersNum / playersNum;
  const progress =
    progressDecimal < 1 && progressDecimal >= 0.99
      ? 99
      : Math.ceil(progressDecimal * 100);
  return (
    <div className="stats-container">
      <table className="game-stats-table">
        <tbody>
          <tr>
            <td>Total catches</td>
            <td>{catchesNum}</td>
          </tr>
          <tr>
            <td>Remaining players</td>
            <td>{alivePlayersNum}</td>
          </tr>
          <tr>
            <td>Days of playing</td>
            <td>{gameDays}</td>
          </tr>
        </tbody>
      </table>
      <div className="progress-ring-wrapper">
        <ProgressRing radius="60" stroke="6" progress={progress} />
        <div className="progress-text-box">
          <div className="progress-number">{progress}%</div>
          <div className="progress-text">remaining</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const { alivePlayersNum, catchesNum, playersNum, startDate } = game;
  return {
    auth,
    profile,
    game,
    alivePlayersNum,
    catchesNum,
    playersNum,
    startDate,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(GameStats);
