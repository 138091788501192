import { SHOW_LOADER, HIDE_LOADER } from "./types";
import { postData } from "../../general";

export const shuffleTargets = () => (dispatch, getState, getFirebase) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: SHOW_LOADER });
      await postData("/shuffleTargets");
      dispatch({ type: HIDE_LOADER });
      resolve(true);
    } catch (err) {
      dispatch({ type: HIDE_LOADER });
      reject(err);
    }
  });
};
