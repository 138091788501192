import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import ContentEditable from "react-contenteditable";

import { submitRules } from "../../redux/actions/gameInfoActions";

import { ReactComponent as PlusIcon } from "../../assets/icons/small-plus-icon.svg";
import { ReactComponent as ResetIcon } from "../../assets/icons/reset-icon.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/small-close-icon.svg";

import { defaultRules } from "../../helpers/defaultRules";

class EditRulesOverlay extends Component {
  constructor(props) {
    super();
    this.state = {
      rules: null,
      rulesElement: undefined,
      sectionElements: [],
      recentlyUpdated: false,
      titleElements: [],
      contentElements: [],
    };
  }

  getRulesObj = () => {
    const { rulesElement } = this.state;
    const sections = rulesElement.getElementsByClassName("section");
    const sectionsArr = Array.prototype.slice.call(sections);
    return sectionsArr.map((section) => {
      const sectionHeaderText = section.querySelector("h2").innerHTML;
      const content = section.querySelector("ul");
      const nodeList = content.getElementsByTagName("li");
      let contentArr = Array.prototype.slice.call(nodeList);
      if (contentArr.length < 1) contentArr = [""];
      let contentTextArr = contentArr.map((item) => item.innerText);
      if (!contentTextArr || contentTextArr.length === 0) contentTextArr = [""];

      return { title: sectionHeaderText, content: contentTextArr };
    });
  };

  deleteSection = (event, sectionIndex) => {
    let newRules;

    newRules = this.getRulesObj();
    if (newRules.length > 1) newRules.splice(sectionIndex, 1);

    this.setState(
      () => ({
        rules: newRules,
        recentlyUpdated: true,
      }),
      () => {
        this.setState(() => ({
          recentlyUpdated: false,
        }));
      }
    );
  };

  addSection = () => {
    let newRules = this.state.rules;
    newRules = [
      ...this.getRulesObj(),
      { title: "New section", content: ["Section content"] },
    ];

    this.setState(
      (state) => ({
        rules: newRules,
        recentlyUpdated: true,
      }),
      () => {
        this.setState(
          (state) => ({
            recentlyUpdated: false,
          }),
          () => {
            this.state.sectionElements[
              this.state.rules.length - 1
            ].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        );
      }
    );
  };

  resetToDefault = () => {
    const newRules =
      defaultRules[this.props.profile.gameEnvironment][
        this.props.profile.subGameEnvironment || "general"
      ] || defaultRules.default.general;
    this.setState((state) => ({
      rules: newRules,
    }));
  };

  disableNewlines = (event) => {
    const keyCode = event.keyCode || event.which;

    if (keyCode === 13) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  checkContent = (event) => {
    const content = event.target.innerHTML;
    const keyCode = event.keyCode || event.which;
    if (!content.includes("<li>") || content == "<li><br></li>") {
      if (keyCode === 8) {
        event.returnValue = false;
        if (event.preventDefault) event.preventDefault();
      }
    }
  };

  publishRules = () => {
    this.props
      .submitRules(this.getRulesObj())
      .then(() => {
        this.props.history.push(this.props.postSaveURL);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  discardChanges = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    if (
      !this.state.rules &&
      isLoaded(this.props.rules) &&
      !isEmpty(this.props.rules)
    ) {
      this.setState((state) => ({
        rules: JSON.parse(this.props.rules),
      }));
    }
  }

  componentDidUpdate() {
    if (
      !this.state.rules &&
      isLoaded(this.props.rules) &&
      !isEmpty(this.props.rules)
    ) {
      this.setState((state) => ({
        rules: JSON.parse(this.props.rules),
      }));
    }
  }

  render() {
    return (
      <div className="edit-rules-overlay">
        {this.state.rules ? (
          <div
            className="edit-rules-display"
            ref={(element) => (this.state.rulesElement = element)}
          >
            {!this.state.recentlyUpdated
              ? this.state.rules.map((section, si) => (
                  <div
                    key={si}
                    ref={(element) =>
                      (this.state.sectionElements[si] = element)
                    }
                    className="section"
                  >
                    <h6>Section {si + 1}</h6>
                    <h2
                      contentEditable="true"
                      suppressContentEditableWarning="true"
                      onKeyPress={this.disableNewlines}
                      data-ph="Section title"
                    >
                      {section.title}
                    </h2>
                    {this.state.rules.length > 1 ? (
                      <button
                        className="delete-btn"
                        onClick={(event) => this.deleteSection(event, si)}
                      >
                        Delete section
                      </button>
                    ) : null}
                    <ul
                      contentEditable="true"
                      suppressContentEditableWarning="true"
                      onKeyDown={this.checkContent}
                    >
                      {section.content.length > 0 ? (
                        section.content.map((text, i) => (
                          <li key={i}>{text}</li>
                        ))
                      ) : (
                        <li></li>
                      )}
                    </ul>
                  </div>
                ))
              : null}
          </div>
        ) : (
          <div className="edit-rules-display">
            <p>Loading rules...</p>
          </div>
        )}
        <div className="controls-container">
          <button
            className="main-btn main-btn--with-icon main-btn--secondary"
            onClick={this.addSection}
          >
            <PlusIcon />
            Add section
          </button>
          <button
            className="main-btn main-btn--with-icon main-btn--secondary"
            onClick={this.resetToDefault}
          >
            <ResetIcon />
            Reset to default
          </button>
          <button
            className="main-btn main-btn--with-icon"
            onClick={this.publishRules}
          >
            <CheckmarkIcon />
            Save & publish
          </button>
          <button
            className="main-btn main-btn--with-icon main-btn--secondary discard-btn"
            onClick={this.discardChanges}
          >
            <CloseIcon />
            Discard changes
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const rulesObj = state.firestore.data.rules || {};
  const rules = rulesObj.content;
  return {
    profile,
    rules,
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, { submitRules }),
    firestoreConnect((props) => [
      {
        collection: "games",
        doc: props.profile.currentGameId,
        subcollections: [{ collection: "otherGameInfo", doc: "rules" }],
        storeAs: "rules",
      },
    ])
  )(EditRulesOverlay)
);
