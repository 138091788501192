import React, { Component } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import BasicHeader from "./BasicHeader";
import GameStartedNavbar from "./GameStartedNavbar";
import OverviewPage from "../Overview/OverviewPage";
import PlayersPage from "../Players/PlayersPage";
import GameEventsPage from "../GameEvents/GameEventsPage";
import GameSettingsPage from "../Setup/GameSettingsPage";
import RulesOverlay from "../Rules/RulesOverlay";
import EditRulesOverlay from "../Rules/EditRulesOverlay";

const GameStartedUI = () => {
  const location = useLocation();
  const rootPath = "home";
  const redirectCheck = () => {
    if (
      !location.pathname.match(
        new RegExp(rootPath + "|players|game-events|game-settings")
      )
    )
      return <Redirect to={`/${rootPath}`} />;
  };
  return (
    <div>
      {redirectCheck()}
      <BasicHeader />
      <GameStartedNavbar />
      <div className="dashboard-container dashboard-container--game-started">
        <Switch>
          <Route path={`/${rootPath}`} component={OverviewPage} />
          <Route path="/players" component={PlayersPage} />
          <Route path="/game-events" component={GameEventsPage} />
          <Route path="/game-settings" exact component={GameSettingsPage} />
          <Route
            path="/game-settings/game-rules"
            exact
            render={() => (
              <RulesOverlay
                closeURL="/game-settings"
                editURL="/game-settings/game-rules/edit"
              />
            )}
          />
          <Route
            path="/game-settings/game-rules/edit"
            exact
            render={() => (
              <EditRulesOverlay postSaveURL="/game-settings/game-rules?recentlyUpdated=true" />
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default GameStartedUI;
