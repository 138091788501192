import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { getFirebase } from "react-redux-firebase";
import { getFirestore } from "redux-firestore";

import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk.withExtraArgument(getFirebase)];

// const enhancerFunc = () => {
//   const devTools =
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__();
//   if (devTools) {
//     return compose(applyMiddleware(...middleware), devTools);
//   } else {
//     return compose(applyMiddleware(...middleware));
//   }
// };

const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

export default store;
