import React, { Component } from 'react';
import { connect } from 'react-redux';

import InviteForm from './InviteForm';

// Import module from separate file
const InviteLink = () => <h2>Invite link</h2>

class InviteSection extends Component {

	componentDidMount() {
		console.log("mounted")
	}

	render() {
		return (
			<div className="section-container invite-container">
				{ this.props.inviteType === "PRIVATE" ? <InviteForm /> : ( this.props.inviteType === "PUBLIC" ? <InviteLink /> : null )}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	inviteType: state.playerInfo.inviteType
})

export default connect(mapStateToProps)(InviteSection);
