// Default rules for all game types

export const defaultRules = {
  school: {
    elementarySchool: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Escape the building through any exit.",
          "Reach any of the safe zones.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Safe zones",
        content: [
          "The school cafeteria",
          "Any classroom during class",
          "Restrooms",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
    highSchool: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Escape the building through any exit.",
          "Reach any of the safe zones.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Safe zones",
        content: [
          "The school cafeteria",
          "Any classroom during class",
          "Restrooms",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
    university: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Escape the building through any exit.",
          "Reach any of the safe zones.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Safe zones",
        content: [
          "The school cafeteria",
          "Any classroom during class",
          "Restrooms",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
  },
  camp: {
    general: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
  },
  event: {
    general: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
  },
  office: {
    general: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
  },
  default: {
    general: [
      {
        title: "How to play",
        content: [
          "Each registered player is given a target among the other players in the same game.",
          "The goal is to collect as many points as possible. Gain points by catching your target.",
          'Catch your target by shouting "CATCHER" from a 5 meter distance and grab their shoulders.',
          "If you manage to catch your target, you register the catch in the app by pressing the catch button.",
          "If you get caught by your catcher, you'll be eliminated from the game.",
        ],
      },
      {
        title: "Methods of defence",
        content: [
          "Grab the shoulders of any nearby person before your catcher catches you.",
          "Or you could just, you know, run!",
        ],
      },
      {
        title: "Side notes",
        content: [
          "If you fail a catch attempt, you can't try again for another 30 minutes",
          "Physical violence is strictly prohibited",
        ],
      },
    ],
  },
};
