import React from "react";
import { Switch, Route } from "react-router-dom";
import "./stylesheets/App.scss";

import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import firebase from "firebase/app";
import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import fbConfig from "./config/fbConfig";

import FullPageLoader from "./Components/Global/FullPageLoader";
import Onboarding from "./Components/Onboarding";
import Dashboard from "./Components/Layout/Dashboard";
import Register from "./Components/Auth/RegisterPage";
import Login from "./Components/Auth/LoginPage";
import GamesPage from "./Components/GamesCenter/GamesCenterPage";
import SupportModal from "./Components/Global/SupportModal";
import ResetPasswordPage from "./Components/Auth/ResetPasswordPage";

const rrfConfig = {
  userProfile: "organizers",
  useFirestoreForProfile: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <div>Loading...</div>;
  return children;
}

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <div className="App">
            <Switch>
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/onboarding" component={Onboarding} />
              <Route path="/games" component={GamesPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route path="/" component={Dashboard} />
            </Switch>
            <FullPageLoader />
            <SupportModal />
          </div>
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default App;
