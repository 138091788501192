import React from 'react';
import { connect } from 'react-redux';

import { setInviteType } from '../../../redux/actions/playerActions'

function InviteTypeSelection(props) {
	return (
		<div className="invite-type-selection">
			<div className="options-container">
				<h1>How do you want your players to sign up to the game?</h1>
				<div className="options-wrapper">
					<div className="option-card" onClick={() => props.setInviteType("PRIVATE")}>
						<h2>Private email invites</h2>
					</div>
					<div className="option-card" onClick={() => props.setInviteType("PUBLIC")}>
						<h2>Public signup link</h2>
					</div>
				</div>
			</div>
		</div>
	)
}

export default connect(null, {setInviteType})(InviteTypeSelection)