import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";

const PendingInvitesInfo = (props) => {
  const { pendingPlayerInvitesNum } = props;
  return (
    <div className="player-list-info pending-invites">
      <h2>Pending invites</h2>
      <div className="player-list-amount">
        Total pending Invites <span>{pendingPlayerInvitesNum}</span>
      </div>
      <Link to="/player-setup/invites" className="player-list-link">
        See all
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, profile } = state.firebase;
  const games = state.firestore.data.games || {};
  const game = games[profile.currentGameId] || {};
  const pendingPlayerInvitesNum = game.pendingPlayerInvitesNum || 0;
  return {
    auth,
    profile,
    pendingPlayerInvitesNum,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: "games", doc: props.profile.currentGameId },
  ])
)(PendingInvitesInfo);
