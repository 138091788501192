import React, { useState } from "react";
import spinnerGif from "../../../assets/gifs/grey-spinner.gif";
import { postData } from "../../../general";

const PlayerListItem = ({ player, gameStarted, functions }) => {
  const {
    firstName,
    lastName,
    email,
    alive,
    points,
    rank,
    lives,
    formatedInviteDate,
    formatedJoinedDate,
    playerInstanceId,
  } = player;

  return (
    <tr>
      {firstName && lastName ? <td>{firstName + " " + lastName}</td> : null}
      {email && <td>{email}</td>}
      {formatedInviteDate && <td>{formatedInviteDate}</td>}
      {formatedJoinedDate && <td>{formatedJoinedDate}</td>}
      {points >= 0 && <td>{points}p</td>}
      {typeof rank === "number" && <td>{rank}</td>}
      {alive && <td className="green">Remaining</td>}
      {alive === false && <td className="red">Eliminated</td>}
      {gameStarted === true && (
        <td>
          <MoreIcon
            player={player}
            alive={alive}
            gameStarted={gameStarted}
            functions={functions}
          />
        </td>
      )}
    </tr>
  );
};

const MoreIcon = ({ player, alive, gameStarted, functions }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const iconClassName = open
    ? "more-icon open"
    : loading
    ? "more-icon loading"
    : "more-icon";

  const toggleModal = () => {
    if (!loading) setOpen((prevState) => !prevState);
  };

  const startLoading = () => {
    setOpen(false);
    setLoading(true);
  };

  const editPlayer = () => {
    functions.editPlayer(player);
    toggleModal();
  };

  const eliminatePlayer = async () => {
    if (!loading) {
      startLoading();
      await postData("/eliminatePlayer", {
        targetPlayerInstanceId: player.playerInstanceId,
      });
      setLoading(false);
    }
  };

  const revivePlayer = async () => {
    if (!loading) {
      startLoading();
      await postData("/revivePlayer", {
        playerInstanceId: player.playerInstanceId,
      });
      setLoading(false);
    }
  };

  return (
    <div className="more-icon-wrapper">
      <div className={iconClassName} onClick={toggleModal}>
        {loading ? (
          <img width="15" src={spinnerGif} />
        ) : (
          <>
            <div className="more-icon-bullet" />
            <div className="more-icon-bullet" />
            <div className="more-icon-bullet" />
          </>
        )}
      </div>
      {open && (
        <div className="more-options-modal">
          <div className="option" onClick={editPlayer}>
            Edit
          </div>
          {alive ? (
            <div className="option" onClick={eliminatePlayer}>
              Eliminate
            </div>
          ) : (
            <div className="option" onClick={revivePlayer}>
              Revive
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlayerListItem;
