import React from "react";
import closeIcon from "../../assets/icons/close-icon.svg";

const InfoModal = ({ title, toggleModal, children }) => {
  return (
    <div className="modal__page-overlay">
      <div className="modal__box info-modal">
        <img
          src={closeIcon}
          alt="close"
          className="close-icon"
          onClick={toggleModal}
        />
        <div className="info-icon">i</div>
        <h1>{title}</h1>
        <p>{children}</p>
        <button className="main-btn" onClick={toggleModal}>
          Got it!
        </button>
      </div>
    </div>
  );
};

export default InfoModal;
