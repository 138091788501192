import React, { useState, useEffect, useRef } from "react";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, handleSelection, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(cities)"] }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, handleSelection)
  );
}

async function handlePlaceSelect(updateQuery, handleSelection) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  handleSelection(query);
  //   console.log(addressObject);
}

const SearchLocationInput = (props) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const {
    placeholder = "Location (city)",
    onSelection,
    defaultValue,
    id = "",
    name = "",
  } = props;

  useEffect(() => {
    if (defaultValue) {
      setQuery(defaultValue);
    }
  }, []);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_API_KEY ||
        "AIzaSyAC45NBjwqGWvB5IfdKPi_RvgsxQ07BveE"
      }&libraries=places&language=en`,
      () => handleScriptLoad(setQuery, onSelection, autoCompleteRef)
    );
  }, []);

  return (
    <input
      ref={autoCompleteRef}
      onChange={(event) => setQuery(event.target.value)}
      placeholder={placeholder}
      value={query}
      id={id}
      name={name}
    />
  );
};

export default SearchLocationInput;
