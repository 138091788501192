import React from "react";
import { NavLink } from "react-router-dom";

const FourthStep = () => {
  return (
    <div className="fourth-step">
      <h1 className="primary-heading">Great! Let's set up the game :)</h1>
      <NavLink className="primary-button" to="/">
        Let's do it
      </NavLink>
    </div>
  );
};

export default FourthStep;
