import React from "react";

import GuideList from "./GuideList";

export default function GuideSection(props) {
  return (
    <div className="section-container guide-container">
      <h2>Tips and tricks</h2>
      <GuideList />
    </div>
  );
}
