import React, { useState } from "react";
import firebase from "firebase";
import { validateEmail } from "../../general";
import { Link } from "react-router-dom";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setLoaded(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        alert(err.message);
      });
  };
  if (!loaded)
    return (
      <div className="auth-page reset-password-page">
        <div className="form-container">
          <h1>Reset password</h1>
          <form onSubmit={resetPassword}>
            <input
              type="email"
              placeholder="example@domain.com"
              className="reset-password-input"
              name="organizationEmail"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <button
              type="submit"
              className="reset-email-btn"
              disabled={!validateEmail(email) || loading}
            >
              {loading ? "Loading..." : "Reset password"}
            </button>
          </form>
        </div>
      </div>
    );
  else
    return (
      <div className="auth-page reset-password-page">
        <div
          style={{
            textAlign: "center",
          }}
        >
          <p className="success-text">
            A password reset email has been sent to {email}
          </p>
          <Link to="/login">
            <button>Back to login</button>
          </Link>
        </div>
      </div>
    );
};

export default ResetPasswordPage;
