import React from "react";
import SearchLocationInput from "../Elements/SearchLocationInput";

const ThirdStep = (props) => {
  return (
    <div className="third-step">
      <InputForm
        handleChange={props.handleChange}
        handleLocationSelection={props.handleLocationSelection}
        values={props.values}
        formContents={props.formContents}
      />
      <button
        className="primary-button"
        disabled={!props.formValid}
        onClick={() => {
          props.submitForm();
        }}
      >
        Submit
      </button>
      <br />
      <button
        className="secondary-button"
        onClick={() => {
          props.changeStep("prev");
        }}
      >
        Go back
      </button>
    </div>
  );
};

// Form component

function InputForm(props) {
  console.log(props.values);
  const {
    gameEnvironmentName,
    location,
    potPlayers,
    eventDuration,
    subGameEnvironment,
  } = props.values;
  const {
    heading,
    nameInput,
    locationInput,
    potPlayersInput,
    eventDurationInput,
    subGameEnvironmentInput,
  } = props.formContents;

  const durationInputElement = !eventDurationInput ? undefined : (
    <div className="input-wrapper">
      <input
        type="number"
        min="1"
        id="eventDuration"
        name="eventDuration"
        value={eventDuration}
        onChange={props.handleChange}
        required
      />
      <label htmlFor="eventDuration">{eventDurationInput}</label>
    </div>
  );

  // TODO subGameEnvironment
  const subGameEnvironmentInputElement = !subGameEnvironmentInput ? undefined : (
    <div className="input-wrapper">
      <select
        name="subGameEnvironment"
        onChange={props.handleChange}
        value={subGameEnvironment}
        className="dropdown-input"
        required
      >
        <option hidden value="">
          {subGameEnvironmentInput}
        </option>
        <option className="dropdown-option" value="elementarySchool">
          Elementary School
        </option>
        <option className="dropdown-option" value="highSchool">
          High School
        </option>
        <option className="dropdown-option" value="university">
          University
        </option>
      </select>
    </div>
  );

  // Return input form

  return (
    <div className="form-container">
      <h1 className="primary-heading">{heading}</h1>
      <div className="input-wrapper">
        <input
          type="text"
          id="gameEnvironmentName"
          name="gameEnvironmentName"
          value={gameEnvironmentName}
          onChange={props.handleChange}
          placeholder=" "
        />
        <label htmlFor="gameEnvironmentName">{nameInput}</label>
      </div>
      <div className="input-wrapper">
        {/* <input
          type="text"
          id="location"
          name="location"
          value={location}
          onChange={props.handleChange}
          required
        /> */}
        <SearchLocationInput
          onSelection={props.handleLocationSelection}
          id="location"
          placeholder=" "
        />
        <label htmlFor="location">{locationInput}</label>
      </div>
      <div className="input-wrapper">
        <input
          type="number"
          id="potPlayers"
          name="potPlayers"
          value={potPlayers}
          onChange={props.handleChange}
          placeholder=" "
        />
        <label htmlFor="potPlayers">{potPlayersInput}</label>
      </div>
      {durationInputElement}
      {subGameEnvironmentInputElement}
      {props.formError ? (
        <p className="form-error">{props.formError.message}</p>
      ) : null}
    </div>
  );
}

// Export form step component

export default ThirdStep;
